import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { UserRoles } from 'src/constants/constants';
import { useAuthContext, useToasts, useUpdateProfile } from 'src/hooks';
import { booleanToString, stringToBoolean } from 'src/utils/helpers';

import { profileDTO } from './profileDTO';
import { UserInfo } from './UserInfo';

const getIds = (data) => data.map((item) => item.id);

const UserInfoFormikWrapper = () => {
  const { TESTER, CUSTOMER, ADMIN } = UserRoles;

  const { user } = useAuthContext();

  const { ErrorMessage } = useToasts();
  const [updateProfile, { loading }] = useUpdateProfile();

  const {
    email: emailData,
    firstName: fName,
    lastName: lName,
    tester,
    customer,
    secondaryAddress,
  } = user;

  const getAdminInitialValues = () => ({
    email: emailData,
    firstName: fName,
    lastName: lName,
  });

  const getCustomerInitialValues = () => ({
    email: emailData,
    firstName: fName,
    lastName: lName,
    brandId: customer?.brand?.id,
  });

  const getTesterInitialValues = () => ({
    email: emailData,
    firstName: fName,
    lastName: lName,
    secondaryAddress,
    ...profileDTO(tester || customer),
    generalShoeSizes: getIds(tester.generalShoeSizes),
    runningShoeSizes: getIds(tester.runningShoeSizes),
    hikingShoeSizes: getIds(tester.hikingShoeSizes),
    sandalShoeSizes: getIds(tester.sandalShoeSizes),
    bottomSizes: getIds(tester.bottomSizes),
    topSizes: getIds(tester.topSizes),
    isWorkForAnOutdoorCompany: booleanToString(tester.isWorkForAnOutdoorCompany),
    isHaveDog: booleanToString(tester.isHaveDog),
    isRecreateWithKids: booleanToString(tester.isRecreateWithKids),
    isCallOrText: booleanToString(tester.isCallOrText),
  });

  const getInitialValues = () => {
    if (user.role === TESTER) return getTesterInitialValues();
    if (user.role === ADMIN) return getAdminInitialValues();
    if (user.role === CUSTOMER) return getCustomerInitialValues();
    return {};
  };

  const handleUpdateProfile = (data, resetForm) =>
    updateProfile({
      variables: {
        input: data,
      },
      onError: () => {
        resetForm();
        ErrorMessage('Error. Please, try again.');
      },
    });

  const handleUpdateTester = (data, resetForm) => {
    const {
      firstName,
      lastName,
      weight,
      age,
      birthdate,
      email,
      phoneNumber,
      country,
      state,
      otherActivities,
      heightMetric,
      heightImperialInch,
      heightImperialFeet,
      weightLbs,
      weightKgs,
      chestInches,
      chestCm,
      waistAtNavelInches,
      waistAtNavelCm,
      waistAtPantsInches,
      waistAtPantsCm,
      hipsInches,
      hipsCm,
      inseamInches,
      inseamCm,
      secondaryAddress: secAddress,
      ...testerInfo
    } = data;

    const formattedActivities = otherActivities?.map(({ title, ...item }) => ({
      ...item,
      name: title,
    }));

    const dataVariable = {
      firstName,
      lastName,
      email,
      testerInfo: {
        ...testerInfo,
        otherActivities: formattedActivities,
        weight: weight?.toString(),
        birthdate: birthdate ? dayjs(birthdate).format('YYYY-MM-DD') : null,
        countryId: country?.id,
        stateId: state?.id,
        phoneNumber: phoneNumber?.toString(),
        heightMetric: heightMetric?.toString(),
        heightImperialInch: heightImperialInch?.toString(),
        heightImperialFeet: heightImperialFeet?.toString(),
        weightLbs: weightLbs?.toString(),
        weightKgs: weightKgs?.toString(),
        chestInches: chestInches?.toString(),
        chestCm: chestCm?.toString(),
        waistAtNavelInches: waistAtNavelInches?.toString(),
        waistAtNavelCm: waistAtNavelCm?.toString(),
        waistAtPantsInches: waistAtPantsInches?.toString(),
        waistAtPantsCm: waistAtPantsCm?.toString(),
        hipsInches: hipsInches?.toString(),
        hipsCm: hipsCm?.toString(),
        inseamInches: inseamInches?.toString(),
        inseamCm: inseamCm?.toString(),
        isWorkForAnOutdoorCompany: stringToBoolean(testerInfo.isWorkForAnOutdoorCompany),
        isHaveDog: stringToBoolean(testerInfo.isHaveDog),
        isRecreateWithKids: stringToBoolean(testerInfo.isRecreateWithKids),
        isCallOrText: stringToBoolean(testerInfo.isCallOrText),
        occupation: testerInfo.occupation,
      },
    };

    handleUpdateProfile(dataVariable, resetForm);
  };

  const handleUpdateCustomer = (data, resetForm) => {
    const { firstName, lastName, email, brandId } = data;

    const dataVariable = {
      firstName,
      lastName,
      email,
      brandId,
    };

    handleUpdateProfile(dataVariable, resetForm);
  };

  const handleUpdateAdmin = (data, resetForm) => {
    const { firstName, lastName, email } = data;

    const dataVariable = {
      firstName,
      lastName,
      email,
    };

    handleUpdateProfile(dataVariable, resetForm);
  };

  const handleOnSubmit = (data, resetForm) => {
    if (user.role === TESTER) {
      handleUpdateTester(data, resetForm);
    }
    if (user.role === CUSTOMER) {
      handleUpdateCustomer(data, resetForm);
    }
    if (user.role === ADMIN) {
      handleUpdateAdmin(data, resetForm);
    }
  };
  return (
    <Formik
      initialValues={getInitialValues()}
      enableReinitialize={true}
      onSubmit={(values, { resetForm }) => handleOnSubmit(values, resetForm)}
    >
      <Form>
        <UserInfo loading={loading} />
      </Form>
    </Formik>
  );
};

export default UserInfoFormikWrapper;
