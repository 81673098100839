import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFormikContext } from 'formik';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { theme } from 'src/theme';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';
import OptionItemBordered from '../components/OptionItemBordered';

const TopSize = ({ topSizePossibleValues }) => {
  const { setFieldValue, values } = useFormikContext();

  const man = topSizePossibleValues?.man;
  const woman = topSizePossibleValues?.woman;

  const handleSizeSelect = (id) => () => {
    if (values?.topSizes?.includes(id)) {
      const filteredOptions = values.topSizes.filter((sizeId) => sizeId !== id);
      setFieldValue('topSizes', filteredOptions);
    } else {
      setFieldValue('topSizes', [...values.topSizes, id]);
    }
  };

  return (
    <ContentWrapper label='Top Size' subLabel='What size tops do you usually wear?'>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            fontSize: '12px',
            lineHeight: 1.33,
            fontWeight: '400',
            marginBottom: '8px',
            color: theme.colors.neutral.dark.gray,
          }}
        >
          Select all that apply
        </Typography>
        <StyledLabel label='Women' mb={2} sx={{ ...testerInfoStyle.label, marginBottom: 2 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {woman?.map((size) => {
              const isSelected = values.topSizes?.includes(size.id);
              return (
                <OptionItemBordered
                  key={size.id}
                  title={size.name}
                  value={size.id}
                  isSelected={isSelected}
                  onClick={handleSizeSelect}
                />
              );
            })}
          </Box>
        </StyledLabel>
        <StyledLabel label='Men' mb={2} sx={{ ...testerInfoStyle.label }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {man?.map((size) => {
              const isSelected = values.topSizes?.includes(size.id);
              return (
                <OptionItemBordered
                  key={size.id}
                  title={size.name}
                  value={size.id}
                  isSelected={isSelected}
                  onClick={handleSizeSelect}
                />
              );
            })}
          </Box>
        </StyledLabel>
      </Box>
    </ContentWrapper>
  );
};

export default TopSize;
