import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { func, instanceOf } from 'prop-types';
import { styles } from 'src/components/userProfile/testerTabs/styles';
import { convertCmToFeetInches, convertFeetInchesToCm } from 'src/utils/helpers';

import {
  DetailGender,
  DetailsAdditionalClothingInfo,
  DetailsAdditionalShoeInfo,
  DetailsBirthday,
  DetailsBottomSize,
  DetailsChestSize,
  DetailsGeneralShoeSize,
  DetailsHeight,
  DetailsHikingBootSize,
  DetailsHips,
  DetailsInseam,
  DetailsRunningShoeSize,
  DetailsSandalSize,
  DetailsShoeWidth,
  DetailsTopSize,
  DetailsWaistAtNavel,
  DetailsWaistAtPants,
  DetailsWeight,
} from './index';

export const Details = ({
  possibleValues = {},
  submitOnValueChange,
  testerProfile,
  toggleModal = null,
}) => {
  const { handleChange, setFieldValue } = useFormikContext();

  const handleChangeInchesToCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertFeetInchesToCm({
        inches: e.target.value,
      }),
    );
  };

  const handleChangeCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertCmToFeetInches({
        cm: e.target.value,
        inInches: true,
      }),
    );
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {testerProfile && (
        <Box sx={styles.requiredWrapper}>
          <span>*</span> Required
        </Box>
      )}
      <DetailsBirthday testerProfile={testerProfile} />
      <DetailGender possibleValues={possibleValues} />
      <DetailsHeight testerProfile={testerProfile} />
      <DetailsWeight testerProfile={testerProfile} />
      <DetailsGeneralShoeSize
        possibleValues={possibleValues}
        submitOnValueChange={submitOnValueChange}
        testerProfile={testerProfile}
        toggleModal={toggleModal}
      />
      <DetailsRunningShoeSize
        possibleValues={possibleValues}
        submitOnValueChange={submitOnValueChange}
        toggleModal={toggleModal}
      />
      <DetailsHikingBootSize
        possibleValues={possibleValues}
        submitOnValueChange={submitOnValueChange}
      />
      <DetailsSandalSize
        possibleValues={possibleValues}
        submitOnValueChange={submitOnValueChange}
      />
      <DetailsShoeWidth
        possibleValues={possibleValues}
        submitOnValueChange={submitOnValueChange}
        testerProfile={testerProfile}
        toggleModal={toggleModal}
      />
      <DetailsAdditionalShoeInfo submitOnValueChange={submitOnValueChange} />
      <DetailsTopSize possibleValues={possibleValues} submitOnValueChange={submitOnValueChange} />
      <DetailsBottomSize
        possibleValues={possibleValues}
        submitOnValueChange={submitOnValueChange}
      />
      <DetailsChestSize
        handleChangeInchesToCm={handleChangeInchesToCm}
        handleChangeCm={handleChangeCm}
        testerProfile={testerProfile}
      />
      <DetailsWaistAtNavel
        handleChangeInchesToCm={handleChangeInchesToCm}
        handleChangeCm={handleChangeCm}
        testerProfile={testerProfile}
      />
      <DetailsWaistAtPants
        handleChangeInchesToCm={handleChangeInchesToCm}
        handleChangeCm={handleChangeCm}
        testerProfile={testerProfile}
      />
      <DetailsHips
        handleChangeInchesToCm={handleChangeInchesToCm}
        handleChangeCm={handleChangeCm}
        testerProfile={testerProfile}
      />
      <DetailsInseam
        handleChangeInchesToCm={handleChangeInchesToCm}
        handleChangeCm={handleChangeCm}
        testerProfile={testerProfile}
      />
      <DetailsAdditionalClothingInfo
        submitOnValueChange={submitOnValueChange}
        toggleModal={toggleModal}
      />
    </Box>
  );
};

Details.propTypes = {
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  values: instanceOf(Object),
  possibleValues: instanceOf(Object),
};
