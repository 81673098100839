import { Box, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { CheckIcon } from 'src/components/UI/icons/CheckIcon';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';
import { stepsStyles } from '../styles';

const TestingFrequency = ({ participationInTestsPossibleValues }) => {
  const { handleChange, values } = useFormikContext();

  const handleOptionChange = (value) => () => {
    const name = 'participationInTests';

    handleChange({
      target: {
        name,
        value,
      },
    });
  };

  return (
    <ContentWrapper
      label='Testing Frequency'
      subLabel='Let us know how often you’d like to be involved.'
    >
      <StyledLabel label='How often would you like to test gear?' sx={testerInfoStyle.label}>
        {participationInTestsPossibleValues?.map((value) => (
          <MenuItem
            key={value}
            value={value}
            sx={stepsStyles.selectItem}
            onClick={handleOptionChange(value)}
          >
            <Box className='check-icon' sx={stepsStyles.checkIcon}>
              <CheckIcon isChecked={values?.participationInTests === value} />
            </Box>
            {value}
          </MenuItem>
        ))}
      </StyledLabel>
    </ContentWrapper>
  );
};

export default TestingFrequency;
