import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { bool, func, instanceOf, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { ChevronBottom, menuItemSx } from 'src/components';

import { styles } from './styles';

const ControlledSelect = ({
  value = [],
  onChange,
  error,
  options = [],
  placeholder,
  controlledSelectWrapper,
  paperContainer,
  renderCustomOptions,
  name,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectValue, setSelectValue] = useState([]);

  useEffect(() => value?.length && setSelectValue(value), [value]);

  const open = Boolean(anchorEl);
  const isSelected = (option) => {
    if (Array.isArray(selectValue)) {
      return Boolean(selectValue?.find((item) => item === option.value));
    }
    return option.value === selectValue;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onChange(selectValue);
    setAnchorEl(null);
  };

  const handleSelectItem = (option) => () => {
    setSelectValue((prev) => {
      const isOptionSelected = isSelected(option);
      if (Array.isArray(prev)) {
        if (isOptionSelected) {
          return prev.filter((item) => item !== option.value);
        }
        return [...prev, option.value];
      }
      if (isOptionSelected) {
        return [];
      }
      return [prev, option.value];
    });
  };

  const internalRenderValue = () => {
    if (!value.length && placeholder) return placeholder;
    if (
      name === 'topSizes' ||
      name === 'bottomSizes' ||
      name === 'generalShoeSizes' ||
      name === 'runningShoeSizes' ||
      name === 'hikingShoeSizes' ||
      name === 'sandalShoeSizes'
    ) {
      return Object.values(options)
        .flat()
        .filter(Boolean)
        .reduce((acc, val) => (value.includes(val.id) ? [...acc, val.code] : acc), [])
        .map((item, i) => {
          if (i === value.length - 1) return item;
          return `${item}, `;
        });
    }
    // якщо вибрані декілька значень, то value це масив, а якщо вибране одне значення то value - string.
    if (Array.isArray(value)) {
      // код нижче потрібен, щоб у інпут селекта потрапляло не value(воно на бек улітає), а title(як і у dropdown).
      const newArr = [];
      options.forEach((option) => {
        value.forEach((val) => {
          if (option.value === val) {
            newArr.push(option.title);
          }
        });
      });
      return newArr.join(', ');
    }

    const option = options.filter((item) => item.value === value);
    return option[0].title;
  };

  const renderOptions = (_options) => {
    return _options.map((option) => (
      <MenuItem
        sx={menuItemSx(isSelected, option)}
        key={option.value}
        onClick={handleSelectItem(option)}
      >
        {option.title}
      </MenuItem>
    ));
  };

  return (
    <>
      <Box
        sx={{
          ...controlledSelectWrapper,
          ...styles.selectContainer,
          ...(open && styles.selectOpen),
          color: !value.length ? '#676767' : '#000000',
          ...(error && {
            border: '1px solid red',
          }),
        }}
        onClick={handleClick}
      >
        {internalRenderValue()}
        <Box sx={open && styles.transformChevron}>
          <ChevronBottom />
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: styles.menuListContainer,
        }}
        PaperProps={{
          sx: {
            ...styles.paperContainer,
            ...paperContainer,
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ListSubheader
          sx={{
            ...styles.label,
            height: '35px',
          }}
        >
          Select all that apply
        </ListSubheader>
        {typeof renderCustomOptions === 'function'
          ? renderCustomOptions(options, name, handleSelectItem, isSelected)
          : renderOptions(options)}
      </Menu>
    </>
  );
};

export default ControlledSelect;

ControlledSelect.propTypes = {
  value: instanceOf(Object),
  onChange: func,
  options: instanceOf(Object),
  placeholder: string,
  controlledSelectWrapper: instanceOf(Object),
  paperContainer: instanceOf(Object),
  error: bool,
  name: string,
  renderCustomOptions: instanceOf(Object),
};
