import { Fragment, Suspense, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loader } from 'src/components';
import { useAuthContext } from 'src/hooks';

import PrivateRoute from './PrivateRoute';
import { routes } from './routes';

const renderRoute = (route, user) => {
  const Layout = route.layout || Fragment;
  const Component = route.component;

  if (route.public) {
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Component />
          </Layout>
        }
      />
    );
  }

  return (
    <Route
      key={route.path}
      path={route.path}
      element={<PrivateRoute route={route} user={user} path={route.path} />}
    />
  );
};

export const Navigation = () => {
  const { isAuth, user, loading } = useAuthContext();

  const routesToMap = useMemo(
    () =>
      routes.filter((route) => {
        if (!isAuth || !route.roles) return true;

        return route.roles.includes(user?.role);
      }),
    [isAuth, user?.role, routes],
  );

  return loading ? (
    <Loader loading />
  ) : (
    <Suspense>
      <Routes>{routesToMap.map((route) => renderRoute(route, user))}</Routes>
    </Suspense>
  );
};
