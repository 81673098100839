import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import CompleteProfileImageUrl from 'src/assets/images/complete-profile.png';

export const CompleteProfileModal = ({ open, toggleModal }) => {
  const navigate = useNavigate();

  const handleNavigateToTests = () => {
    navigate('/tests');

    toggleModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleNavigateToTests}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      componentsProps={{
        backdrop: {
          sx: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: '40px',
          borderRadius: '4px',
          minWidth: 300,
          maxWidth: '728px',
          width: '100%',
        }}
      >
        <Box
          id='modal-title'
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24px',
            paddingTop: '6px',
            paddingBottom: '6px',
            marginBottom: '12px',
          }}
        >
          Your profile is complete!
        </Box>
        <Box
          id='modal-description'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '12px',
            '& img': {
              maxHeight: '291px',
            },
          }}
        >
          <img src={CompleteProfileImageUrl} alt='Preview img'></img>
          <Typography
            sx={{
              maxWidth: '648px',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
            }}
          >
            Photo by Forest Woodward
          </Typography>
          <Typography
            sx={{
              maxWidth: '648px',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            Now that your profile is complete, we can start inviting you to tests. Keep your eye on
            your email and we’ll be in touch soon.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 3,
          }}
        >
          <Button
            onClick={handleNavigateToTests}
            variant='contained'
            sx={{ borderRadius: '24px', px: 4, py: 1 }}
          >
            View My Tests
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
