import { parsePhoneNumber } from 'awesome-phonenumber';
import * as Yup from 'yup';

import { ACTIVITY_FIELDS } from './TesterInfo/utils/options';

export const basicsSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
});

export const primaryShippingAddressSchema = Yup.object({
  shipPreference: Yup.string().nullable().required('Required'),
  postalCode: Yup.string().nullable().required('Required'),
  addressOne: Yup.string().nullable().required('Required'),
  addressTwo: Yup.string().nullable().required('Required'),
  country: Yup.object().nullable().required('Required'),
  city: Yup.string().nullable().required('Required'),
  state: Yup.object().nullable().required('Required'),
});

export const contactSchema = Yup.object({
  phoneNumber: Yup.string()
    .nullable()
    .required('Required')
    .test('check-length-number', 'Max. length 11', (_, obj) => {
      if (obj.originalValue && obj.originalValue.length !== 11) {
        return false;
      }
      return true;
    })
    .test(
      'check-is-valid-phone',
      'The number must be in the format 1NXXNXXXXXX (US and Canada only)',
      (_, obj) => {
        if (!['1'].includes(obj.originalValue)) {
          return parsePhoneNumber(`+${obj.originalValue}`).possible;
        }

        return true;
      },
    ),
});

export const industryInsiderSchema = Yup.object({
  isWorkForAnOutdoorCompany: Yup.boolean().required('Choose option'),
});

export const doForALivingSchema = Yup.object({
  occupation: Yup.string().nullable().required('Required'),
});

export const aboutSchema = Yup.object({
  gender: Yup.string().required('Required'),
  birthdate: Yup.string().required('Required'),
  relatesOutdoorProductTestingDescription: Yup.string().required('Required'),
});

export const outsideDogsorKidsSchema = Yup.object({
  isHaveDog: Yup.boolean().required('Choose option'),
  isRecreateWithKids: Yup.boolean().required('Choose option'),
});

export const gearSchema = Yup.object({
  typeGearWouldTest: Yup.array().min(1, 'Please choose at least one option').required('Required'),
});

export const demographicshSchema = Yup.object({
  weightLbs: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
  weightKgs: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
  heightImperialFeet: Yup.number()
    .nullable()
    .min(1, 'Must be greater than zero')
    .required('Required'),
  heightImperialInch: Yup.number().nullable().required('Required'),
  heightMetric: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
});
export const topSizeSchema = Yup.object({
  topSizes: Yup.array().min(1, 'Please choose at least one option').required('Required'),
});

export const chestMeasurementSchema = Yup.object({
  chestInches: Yup.number().min(1, 'Must be greater than zero').required('Required'),
  chestCm: Yup.number().min(1, 'Must be greater than zero').required('Required'),
});

export const bottomSizeSchema = Yup.object({
  bottomSizes: Yup.array().min(1, 'Please choose at least one option').required('Required'),
});

export const waistAndHipsMeasurementsSchema = Yup.object({
  waistAtNavelInches: Yup.number()
    .nullable()
    .min(1, 'Must be greater than zero')
    .required('Required'),
  waistAtNavelCm: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
  waistAtPantsInches: Yup.number()
    .nullable()
    .min(1, 'Must be greater than zero')
    .required('Required'),
  waistAtPantsCm: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
  hipsInches: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
  hipsCm: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
});

export const hemLengthSchema = Yup.object({
  inseamInches: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
  inseamCm: Yup.number().nullable().min(1, 'Must be greater than zero').required('Required'),
});

export const shoeSizeSchema = Yup.object({
  generalShoeSizes: Yup.array().min(1, 'Please choose at least one option').required('Required'),
});

export const additionalFootwearInfoSchema = Yup.object({
  shoeSizeAdditionalInfo: Yup.string().nullable().required('Required'),
});

export const activitiesSchema = Yup.object({
  campingFreq: Yup.string().test(
    'at-least-one-not-never',
    'Choose at least one activity',
    (_, obj) => {
      const values = obj.parent;

      return ACTIVITY_FIELDS.some((field) => values[field] !== 'NEVER');
    },
  ),
});

export const testingFrequencySchema = Yup.object({
  participationInTests: Yup.string().required('Required'),
});

export const broughtMergFamilySchema = Yup.object({
  didYouHearAboutUs: Yup.string().required('Required'),
});

export const UserAgreementSchema = Yup.object({
  agreementSigned: Yup.string().nullable().required('Required'),
});

export const testerInfoSchemas = [
  basicsSchema,
  primaryShippingAddressSchema,
  contactSchema,
  industryInsiderSchema,
  doForALivingSchema,
  aboutSchema,
  outsideDogsorKidsSchema,
  gearSchema,
  demographicshSchema,
  topSizeSchema,
  chestMeasurementSchema,
  bottomSizeSchema,
  waistAndHipsMeasurementsSchema,
  hemLengthSchema,
  shoeSizeSchema,
  additionalFootwearInfoSchema,
  activitiesSchema,
  testingFrequencySchema,
  broughtMergFamilySchema,
  UserAgreementSchema,
];
