import { Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { Flex, StyledLabel } from 'src/components/common/CommonStyledComponents';
import {
  convertCmToFeetInches,
  convertFeetInchesToCm,
  convertKgToLbs,
  convertLbsToKg,
} from 'src/utils/helpers';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const Demographics = () => {
  const { handleChange, values, errors, touched, handleBlur, setFieldValue } = useFormikContext();

  const handleChangeLbs = (e) => {
    handleChange(e);

    setFieldValue('weightKgs', convertLbsToKg(e.target.value));
  };

  const handleChangeKg = (e) => {
    handleChange(e);
    setFieldValue('weightLbs', convertKgToLbs(e.target.value));
  };

  const handleChangeFeet = (e) => {
    handleChange(e);
    setFieldValue(
      'heightMetric',
      convertFeetInchesToCm({
        feet: e.target.value,
        inches: values.heightImperialInch,
      }),
    );
  };

  const handleChangeInches = (e) => {
    handleChange(e);
    setFieldValue(
      'heightMetric',
      convertFeetInchesToCm({
        feet: values.heightImperialFeet,
        inches: e.target.value,
      }),
    );
  };

  const handleChangeCmToFeetInches = (e) => {
    handleChange(e);

    const data = convertCmToFeetInches({
      cm: e.target.value,
    });

    setFieldValue('heightImperialFeet', data.feet);
    setFieldValue('heightImperialInch', data.inches);
  };

  return (
    <ContentWrapper label='Demographics' subLabel='Give us a sense of your body type.'>
      <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
        <StyledLabel label='Weight (lbs)' sx={testerInfoStyle.label}>
          <TextField
            type='number'
            size='small'
            name='weightLbs'
            onInput={handleChangeLbs}
            value={values.weightLbs}
            error={touched.weightLbs && !!errors.weightLbs}
            helperText={touched.weightLbs && errors.weightLbs}
            placeholder='Lbs'
            onBlur={handleBlur}
          />
        </StyledLabel>
        <StyledLabel label='Weight (kgs)' sx={testerInfoStyle.label}>
          <TextField
            type='number'
            size='small'
            name='weightKgs'
            onInput={handleChangeKg}
            value={values.weightKgs}
            error={touched.weightKgs && !!errors.weightKgs}
            helperText={touched.weightKgs && errors.weightKgs}
            placeholder='Kgs'
            onBlur={handleBlur}
          />
        </StyledLabel>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
        <StyledLabel label='Height (Imperial)' sx={testerInfoStyle.label}>
          <Flex gap={1}>
            <TextField
              type='number'
              name='heightImperialFeet'
              size='small'
              onInput={handleChangeFeet}
              value={values.heightImperialFeet}
              error={touched.heightImperialFeet && !!errors.heightImperialFeet}
              helperText={touched.heightImperialFeet && errors.heightImperialFeet}
              placeholder='Feet'
              onBlur={handleBlur}
            />
            <TextField
              type='number'
              name='heightImperialInch'
              size='small'
              onInput={handleChangeInches}
              value={values.heightImperialInch}
              error={touched.heightImperialInch && !!errors.heightImperialInch}
              helperText={touched.heightImperialInch && errors.heightImperialInch}
              placeholder='Inches'
              onBlur={handleBlur}
            />
          </Flex>
        </StyledLabel>
        <StyledLabel label='Height (Metric)' sx={testerInfoStyle.label}>
          <TextField
            type='number'
            name='heightMetric'
            size='small'
            onInput={handleChangeCmToFeetInches}
            value={values.heightMetric}
            error={touched.heightMetric && !!errors.heightMetric}
            helperText={touched.heightMetric && errors.heightMetric}
            placeholder='cm'
            onBlur={handleBlur}
          />
        </StyledLabel>
      </Box>
    </ContentWrapper>
  );
};

export default Demographics;
