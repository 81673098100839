import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { InseamModal } from 'src/components/testerDetail/tabs/DetailsTab/modal/InseamModal';
import { theme } from 'src/theme';
import { convertCmToFeetInches, convertFeetInchesToCm } from 'src/utils/helpers';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const HemLength = () => {
  const { handleChange, values, errors, touched, handleBlur, setFieldValue } = useFormikContext();
  const [open, toggleModal] = useState(false);

  const handleChangeInchesToCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertFeetInchesToCm({
        inches: e.target.value,
      }),
    );
  };
  const handleChangeCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertCmToFeetInches({
        cm: e.target.value,
        inInches: true,
      }),
    );
  };

  const handleToggleModal = () => {
    toggleModal((prev) => !prev);
  };

  return (
    <ContentWrapper label='Hem Length'>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: '4px' }}>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '700',
          }}
        >
          Inseam
        </Typography>
        <Button
          sx={{
            color: theme.colors.tertiary,
            padding: 0,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '700',
          }}
          onClick={handleToggleModal}
        >
          How to measure?
        </Button>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
        <StyledLabel label='Imperial (US)' sx={testerInfoStyle.label}>
          <TextField
            type='number'
            size='small'
            name='inseamInches'
            onInput={handleChangeInchesToCm('inseamCm')}
            value={values.inseamInches}
            error={touched.inseamInches && !!errors.inseamInches}
            helperText={touched.inseamInches && errors.inseamInches}
            placeholder='Inches'
            onBlur={handleBlur}
          />
        </StyledLabel>
        <StyledLabel label='Metric' sx={testerInfoStyle.label}>
          <TextField
            type='number'
            size='small'
            name='inseamCm'
            onInput={handleChangeCm('inseamInches')}
            value={values.inseamCm}
            error={touched.inseamCm && !!errors.inseamCm}
            helperText={touched.inseamCm && errors.inseamCm}
            placeholder='cm'
            onBlur={handleBlur}
          />
        </StyledLabel>
      </Box>
      <InseamModal open={open} toggleModal={handleToggleModal} />
    </ContentWrapper>
  );
};

export default HemLength;
