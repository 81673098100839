import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { instanceOf } from 'prop-types';
import { useState } from 'react';
import { DetailLabel } from 'src/components/index';
import { DetailSelectMultiple } from 'src/components/UI/detail/DetailSelectMultiple';
import { styles } from 'src/components/userProfile/testerTabs/styles';
import {
  handleSetMultipleValues,
  handleShoeSizeValue,
  handleShoeSizeValueOrFallback,
} from 'src/utils/helpers';

import { getShoeSizeOptions } from '../getShoeSizeOptions';
import { detailsTabStyles } from './backDropStyles';

export const DetailsGeneralShoeSize = ({
  possibleValues,
  submitOnValueChange = true,
  testerProfile,
  toggleModal,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, setFieldValue } = useFormikContext();
  const { shoeSizeOptions } = possibleValues;

  if (!shoeSizeOptions) return null;

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };
  return (
    <DetailLabel
      label={
        testerProfile ? (
          <Box sx={styles.required}>
            General Shoe Size <span>*</span>
          </Box>
        ) : (
          'General Shoe Size'
        )
      }
    >
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />
        <DetailSelectMultiple
          name='generalShoeSizes'
          handleChange={handleSetMultipleValues(setFieldValue)}
          value={values.generalShoeSizes}
          placeholder='Select shoe size'
          renderTextValue={handleShoeSizeValueOrFallback(shoeSizeOptions, 'Select shoe size')}
          renderValue={handleShoeSizeValue(shoeSizeOptions)}
          wrapItems
          multiple
          submitOnValueChange={submitOnValueChange}
          toggleModal={toggleModal}
        >
          {getShoeSizeOptions({
            possibleValues,
          })}
        </DetailSelectMultiple>
      </Box>
    </DetailLabel>
  );
};

DetailsGeneralShoeSize.propTypes = {
  possibleValues: instanceOf(Object),
};
