import { Box, Button, Divider, MenuItem, MenuList, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { CheckIconDefault, PlusIcon } from 'src/components/UI';
import { theme } from 'src/theme';

import { stepsStyles } from '../styles';
import { USER_AGREEMENT_OPTIONS } from '../utils/options';

const UserAgreementContent = () => {
  const { values, setFieldValue } = useFormikContext();

  const [optionOpenedId, setOptionOpenedId] = useState(0);

  const hadleSetAgreement = () => {
    if (!values.agreementSigned)
      setFieldValue('agreementSigned', new Date().toISOString().split('T')[0]);
    else setFieldValue('agreementSigned', null);
  };

  const handlerSetOptionOpenedId = (id) => () => {
    if (optionOpenedId === id) {
      setOptionOpenedId(0);
    } else setOptionOpenedId(id);
  };

  const isSelected = values.agreementSigned;
  return (
    <>
      <Typography
        sx={{ fontSize: '14px', lineHeight: '20px', letterSpacing: 0, marginBottom: '20px' }}
      >
        By signing, you are agreeing to all of the provisions in this Agreement. Included in these
        terms is your agreement to:
      </Typography>
      <MenuList sx={stepsStyles.userAgreementList}>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Actively use and evaluate the product being tested
        </MenuItem>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Provide the types of feedback requested and respond to questions
        </MenuItem>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Provide the types of feedback requested and respond to questions
        </MenuItem>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Give MERG all rights to use any feedback you submit
        </MenuItem>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Not publicize that you are participating in the field-testing program
        </MenuItem>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Not share pictures or videos (including social media) of the products you receive
        </MenuItem>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Not sell or transfer ownership of any products
        </MenuItem>
        <MenuItem sx={stepsStyles.userAgreementListItem}>
          Return any products, if requested
        </MenuItem>
      </MenuList>
      <Divider
        sx={{ marginBottom: '20px', paddingTop: '20px', color: theme.colors.neutral.light.normal }}
      />
      <Typography
        sx={{ fontSize: '14px', lineHeight: '20px', letterSpacing: 0, marginBottom: '20px' }}
      >
        This TESTER AGREEMENT (the “Agreement”) is entered into between you, (“Tester”) and MOUNTAIN
        EQUIPMENT RESEARCH GROUP, LLC, a Utah limited liability company (“MERG”), and is effective
        as of the date of signature by Tester.
      </Typography>
      <Typography
        sx={{ fontSize: '14px', lineHeight: '20px', letterSpacing: 0, marginBottom: '12px' }}
      >
        Tester is an individual who desires to participate in a program for the testing of
        prototypes and products (“Testing Program”). As part of the Testing Program, MERG may
        provide access to confidential information and products currently in development by various
        outdoor recreation manufacturers. In exchange, MERG will receive the benefit of Tester’s
        feedback and reports as a field-tester of such prototypes and products. By executing this
        Agreement, Tester agrees to be bound by the terms of this Agreement, which will govern the
        Testing Program and Tester’s responsibilities with regard to the Test Products.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          alignItems: 'center',
          marginBottom: '12px',
        }}
      >
        {USER_AGREEMENT_OPTIONS.map((option) => (
          <Box key={option.id} sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 0',
                cursor: 'pointer',
              }}
              onClick={handlerSetOptionOpenedId(option.id)}
            >
              <Typography sx={{ fontSize: '14px', lineHeight: 1.42, textTransform: 'uppercase' }}>
                {option.title}
              </Typography>
              <Box>
                <PlusIcon width={28} color={theme.colors.neutral.dark.mid} />
              </Box>
            </Box>
            {option.id === optionOpenedId && (
              <Box>
                {option.options.map((text, index) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: 0,
                      marginBottom: '12px',
                    }}
                  >
                    {text}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Button
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          pading: '8px 0',
          width: '100%',
          backgroundColor: isSelected ? theme.colors.tertiary : theme.colors.neutral.white,
          color: isSelected ? theme.colors.neutral.white : theme.colors.neutral.black,
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: isSelected ? theme.colors.tertiary : 'rgba(0, 0, 0, 0.04)',
          },
        }}
        onClick={hadleSetAgreement}
      >
        <Box sx={stepsStyles.checkIcon}>
          <CheckIconDefault
            color={isSelected ? theme.colors.neutral.white : theme.colors.neutral.light.normal}
          />
        </Box>
        I agree
      </Button>
    </>
  );
};

export default UserAgreementContent;
