import { Box } from '@mui/material';
import React from 'react';
import { theme } from 'src/theme';

const OptionItemBordered = ({ value, title, isSelected, onClick }) => {
  return (
    <Box
      sx={{
        padding: '4px 16px',
        borderRadius: '8px',
        border: `1px solid ${theme.colors.neutral.black}`,
        fontSize: '14px',
        lineHeight: 1.42,
        cursor: 'pointer',
        backgroundColor: isSelected ? theme.colors.tertiary : theme.colors.neutral.white,
        color: isSelected ? theme.colors.neutral.white : theme.colors.neutral.black,
        transition: 'background-color 0.2s',
        '&:hover': {
          ...(!isSelected && {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          }),
        },
      }}
      onClick={onClick(value)}
    >
      {title}
    </Box>
  );
};

export default OptionItemBordered;
