import { Box, Typography } from '@mui/material';

const ContentWrapper = ({ label, subLabel, children }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          py: '6px',
          marginBottom: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            lineHeight: 1.16,
            fontWeight: '700',
          }}
        >
          {label}
        </Typography>
        {subLabel && (
          <Typography
            sx={{
              fontSize: '16px',
            }}
          >
            {subLabel}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default ContentWrapper;
