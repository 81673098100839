import { Avatar, Box, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { Flex, StyledLabel } from 'src/components/common/CommonStyledComponents';
import { useAuthContext } from 'src/hooks';
import { theme } from 'src/theme';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const Basics = ({ toggleChangeAvatarModal }) => {
  const { user } = useAuthContext();
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext();
  return (
    <ContentWrapper
      label='The Basics'
      subLabel='Tell us your name. You can even upload a profile picture so we can put a face with a name.'
    >
      <StyledLabel el label='First Name' sx={testerInfoStyle.label}>
        <TextField
          size='small'
          name='firstName'
          onChange={handleChange}
          value={values.firstName}
          error={touched.firstName && !!errors.firstName}
          helperText={touched.firstName && errors.firstName}
          placeholder='Enter First Name'
          onBlur={handleBlur}
        />
      </StyledLabel>
      <StyledLabel label='Last Name' sx={testerInfoStyle.label}>
        <TextField
          size='small'
          name='lastName'
          onChange={handleChange}
          value={values.lastName}
          error={touched.lastName && !!errors.lastName}
          helperText={touched.lastName && errors.lastName}
          placeholder='Enter Last Name'
          onBlur={handleBlur}
        />
      </StyledLabel>
      <StyledLabel
        label='Profile Picture'
        sx={{
          ...testerInfoStyle.label,
          '& .MuiBox-root': { marginBottom: 1 },
          marginBottom: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1.33,
            color: theme.colors.neutral.dark.mid,
            marginBottom: 0,
          }}
        >
          Optional
        </Typography>
      </StyledLabel>
      <Flex
        sx={{
          gap: 4,
        }}
      >
        <Avatar
          sx={{
            width: '40px',
            height: '40px',
          }}
          alt='avatar'
          src={`${process.env.REACT_APP_DOMAIN}/${user.imageProfileLink}`}
        />
        <Box>
          <Flex
            sx={{
              '& .MuiBox-root': { marginBottom: 0 },
              cursor: 'pointer',
              border: `1px solid #000000`,
              borderRadius: '4px',
              px: 4,
              py: 1,
            }}
            role='button'
            onClick={toggleChangeAvatarModal}
          >
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {user.imageProfileLink ? 'Change Photo' : 'Add Profile Picture'}
            </Typography>
          </Flex>
        </Box>
      </Flex>
    </ContentWrapper>
  );
};

export default Basics;
