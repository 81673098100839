import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const BroughtMergFamily = () => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext();

  return (
    <ContentWrapper label='What brought you to the MERG family?'>
      <StyledLabel
        label='How did you learn about Mountain Equipment Research Group? Feel free to name names so that we can say thank you!'
        sx={testerInfoStyle.label}
      >
        <TextField
          size='small'
          name='didYouHearAboutUs'
          onInput={handleChange}
          value={values.didYouHearAboutUs}
          error={touched.didYouHearAboutUs && !!errors.didYouHearAboutUs}
          helperText={touched.didYouHearAboutUs && errors.didYouHearAboutUs}
          onBlur={handleBlur}
          multiline
          placeholder='Tell us how you found MERG'
          sx={{ width: '100%' }}
          InputProps={{
            sx: {
              width: '100%',
              minHeight: '151px',
              pb: 10,
              alignItems: 'start',
              fontSize: 14,
            },
          }}
        />
      </StyledLabel>
    </ContentWrapper>
  );
};

export default BroughtMergFamily;
