import { Navigate } from 'react-router-dom';
import { TestOverview } from 'src/components/testOverview/TestOverview';
import { UserRoles } from 'src/constants/constants';
import { AdminLayout } from 'src/layouts/AdminLayout';
import { CustomerLayout } from 'src/layouts/CustomerLayout';
import { MainLayout } from 'src/layouts/MainLayout';
import { NonAuthorizatedLayout } from 'src/layouts/NonAuthorizatedLayout';
import { TesterLayout } from 'src/layouts/TesterLayout';
import Admins from 'src/pages/Admins';
import { AdminsProfile } from 'src/pages/AdminsProfile';
import { CreateAccount } from 'src/pages/CreateAccount';
import CreatePassword from 'src/pages/CreatePassword';
import CustomerDetail from 'src/pages/CustomerDetail';
import CustomerMyTests from 'src/pages/CustomerMyTests';
import InviteToTest from 'src/pages/InviteToTest';
import InviteToTestResponce from 'src/pages/InviteToTestResponce';
import Login from 'src/pages/Login';
import { MyTestDetail } from 'src/pages/MyTestDetail';
import { PageNotMatch } from 'src/pages/PageNotMatch';
import TesterDetail from 'src/pages/TesterDetail';
import TesterMyTests from 'src/pages/TesterMyTests';
import TesterProfileComplete from 'src/pages/TesterProfileComplete';
import Testers from 'src/pages/Testers';
import TesterWelcomePage from 'src/pages/TesterWelcomePage';
import Tests from 'src/pages/Tests';
import UserRemovedGoodBye from 'src/pages/thankYouPages/UserRemovedGoodBye';
import { UserProfile } from 'src/pages/UserProfile';

import Customers from '../pages/Customers';

const { ADMIN, CUSTOMER, TESTER } = UserRoles;

export const routes = [
  {
    path: '/',
    component: () => <Navigate to='/tests' />,
    roles: [ADMIN, TESTER, CUSTOMER],
  },
  {
    path: 'login',
    component: Login,
    public: true,
    layout: NonAuthorizatedLayout,
  },
  {
    path: 'reset-password',
    component: CreatePassword,
    public: true,
    layout: NonAuthorizatedLayout,
  },
  {
    path: 'good-bye',
    component: UserRemovedGoodBye,
    public: true,
    layout: NonAuthorizatedLayout,
  },
  {
    path: 'testers',
    layout: MainLayout,
    component: Testers,
    roles: [ADMIN],
  },
  {
    path: 'tests',
    layout: TesterLayout,
    component: TesterMyTests,
    roles: [TESTER],
  },
  {
    path: 'tests',
    layout: CustomerLayout,
    component: CustomerMyTests,
    roles: [CUSTOMER],
  },
  {
    path: 'tests',
    layout: MainLayout,
    component: Tests,
    roles: [ADMIN],
  },
  {
    path: 'tests/:id',
    layout: MainLayout,
    component: TestOverview,
    roles: [ADMIN],
  },
  {
    path: 'tests/:id',
    layout: TesterLayout,
    component: MyTestDetail,
    roles: [TESTER],
  },
  {
    path: 'tests/:id',
    layout: CustomerLayout,
    component: MyTestDetail,
    roles: [CUSTOMER],
  },
  {
    path: 'customers',
    layout: MainLayout,
    component: Customers,
    roles: [ADMIN],
  },
  {
    path: 'testers/:id',
    layout: MainLayout,
    component: TesterDetail,
    roles: [ADMIN],
  },
  {
    path: 'customers/:id',
    layout: MainLayout,
    component: CustomerDetail,
    roles: [ADMIN],
  },
  {
    path: 'admins/:id',
    layout: MainLayout,
    component: AdminsProfile,
    roles: [ADMIN],
  },
  {
    path: 'profile',
    layout: TesterLayout,
    component: UserProfile,
    roles: [TESTER],
  },
  {
    path: 'profile',
    layout: CustomerLayout,
    component: UserProfile,
    roles: [CUSTOMER],
  },
  {
    path: 'profile',
    layout: AdminLayout,
    component: UserProfile,
    roles: [ADMIN],
  },
  {
    path: 'invite-to-test',
    layout: TesterLayout,
    component: InviteToTest,
    public: true,
  },
  {
    path: 'invite-to-test/response',
    layout: TesterLayout,
    component: InviteToTestResponce,
    public: true,
  },
  {
    path: 'create-account',
    layout: TesterLayout,
    component: CreateAccount,
    public: true,
  },
  {
    path: 'admins',
    layout: MainLayout,
    component: Admins,
    roles: [ADMIN],
  },
  {
    path: 'welcome',
    layout: TesterLayout,
    component: TesterWelcomePage,
    roles: [TESTER],
  },
  {
    path: 'welcome/profile',
    layout: TesterLayout,
    component: TesterProfileComplete,
    roles: [TESTER],
  },
  {
    path: '404',
    layout: NonAuthorizatedLayout,
    component: PageNotMatch,
    public: true,
  },
  {
    path: '*',
    layout: NonAuthorizatedLayout,
    component: PageNotMatch,
    public: true,
  },
];
