import { Box, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ChevronDown } from 'src/assets/icons';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { AddressSelect, DetailSelect } from 'src/components/UI';
import { useGeo } from 'src/hooks/useGeo';
import { theme } from 'src/theme';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';
import { stepsStyles } from '../styles';

const PrimaryShippingAddress = () => {
  const { handleChange, values, errors, touched, handleBlur, setFieldValue } = useFormikContext();

  const { countries, states, setCountryId } = useGeo();

  useEffect(() => {
    setCountryId(values.country?.id);
  }, [values.country?.id, setCountryId]);

  return (
    <ContentWrapper label='Primary Shipping Address' subLabel='Where should we ship your gear?'>
      <StyledLabel label='Shipping Preference' sx={testerInfoStyle.label}>
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1.33,
            marginBottom: '8px',
            color: theme.colors.neutral.dark.mid,
          }}
        >
          We work with UPS, you can change to USPS if required
        </Typography>
        <DetailSelect
          name='shipPreference'
          handleChange={handleChange}
          value={values.shipPreference}
          possibleValues={['FedEx/UPS', 'UPS']}
          placeholder=''
          iconComponent={() => (
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <ChevronDown />
            </Box>
          )}
          alwaysShown
          defaultOpen={false}
          selectField={stepsStyles.shipPreferenceSelect}
        />
      </StyledLabel>
      <StyledLabel label='Country' sx={testerInfoStyle.label}>
        <AddressSelect
          value={values.country || ''}
          name='country'
          possibleValues={countries}
          handleChange={setFieldValue}
          placeholder='Select Country'
          sx={stepsStyles.addressSelectWrapper}
          popupIcon={
            <Box>
              <ChevronDown />
            </Box>
          }
          error={touched.country && !!errors.country}
          helperText={touched.country && errors.country}
          onBlur={handleBlur}
        />
      </StyledLabel>
      <StyledLabel label='Address 1' sx={testerInfoStyle.label}>
        <TextField
          name='addressOne'
          onChange={handleChange}
          value={values.addressOne || ''}
          placeholder='Address 1'
          sx={stepsStyles.textFieldWrapper}
          error={touched.addressOne && !!errors.addressOne}
          helperText={touched.addressOne && errors.addressOne}
          onBlur={handleBlur}
        />
      </StyledLabel>
      <StyledLabel label='Address 2' sx={testerInfoStyle.label}>
        <TextField
          name='addressTwo'
          onChange={handleChange}
          value={values.addressTwo || ''}
          placeholder='Apt or Unit Number'
          sx={stepsStyles.textFieldWrapper}
          error={touched.addressTwo && !!errors.addressTwo}
          helperText={touched.addressTwo && errors.addressTwo}
          onBlur={handleBlur}
        />
      </StyledLabel>
      <StyledLabel label='City' sx={testerInfoStyle.label}>
        <TextField
          name='city'
          placeholder='Enter City'
          onChange={handleChange}
          value={values.city || ''}
          sx={stepsStyles.textFieldWrapper}
          error={touched.city && !!errors.city}
          helperText={touched.city && errors.city}
          onBlur={handleBlur}
        />
      </StyledLabel>
      <StyledLabel label='State/Province' sx={testerInfoStyle.label}>
        <AddressSelect
          value={values.state || ''}
          name='state'
          possibleValues={states}
          handleChange={setFieldValue}
          placeholder='Select State'
          getOptionLabel={(option) => option.stateCode || ''}
          sx={stepsStyles.addressSelectWrapper}
          popupIcon={
            <Box>
              <ChevronDown />
            </Box>
          }
          error={touched.state && !!errors.state}
          helperText={touched.state && errors.state}
          onBlur={handleBlur}
        />
      </StyledLabel>
      <StyledLabel label='Postal Code' sx={testerInfoStyle.label}>
        <TextField
          name='postalCode'
          placeholder='Enter Postal Code'
          onChange={handleChange}
          value={values.postalCode || ''}
          sx={{
            ...stepsStyles.textFieldWrapper,
          }}
          error={touched.postalCode && !!errors.postalCode}
          helperText={touched.postalCode && errors.postalCode}
          onBlur={handleBlur}
        />
      </StyledLabel>
    </ContentWrapper>
  );
};

export default PrimaryShippingAddress;
