import { Box } from '@mui/material';
import { theme } from 'src/theme';

import ContentWrapper from '../components/ContentWrapper';
import UserAgreementContent from '../components/UserAgreementContent';

const UserAgreement = () => {
  return (
    <ContentWrapper
      label='User Agreement'
      subLabel='Please read and agree to the MERG terms and conditions in order to be considered for a
          field-tester position.'
    >
      <Box
        sx={{
          height: 'calc(100vh - 336px)',
          padding: '20px 28px',
          border: `1px solid ${theme.colors.neutral.light.normal}`,
          borderRadius: '8px',
          overflow: 'auto',
          marginBottom: '12px',
        }}
      >
        <UserAgreementContent />
      </Box>
    </ContentWrapper>
  );
};

export default UserAgreement;
