import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const Contact = () => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext();
  return (
    <ContentWrapper
      label='Contact'
      subLabel='Occasionally, we may need to reach out via text or phone call.'
    >
      <StyledLabel label='Phone number' sx={testerInfoStyle.label}>
        <TextField
          size='small'
          name='phoneNumber'
          onInput={handleChange}
          value={values.phoneNumber}
          error={touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          placeholder='Enter phone number'
          onBlur={handleBlur}
        />
      </StyledLabel>
    </ContentWrapper>
  );
};

export default Contact;
