import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';
import { useAuthContext, useCompleteTesterProfile, useToasts } from 'src/hooks';
import { useGeo } from 'src/hooks/useGeo';
import { booleanToString, stringToBoolean } from 'src/utils/helpers';

import { profileDTO } from '../profileDTO';
import { testerInfoSchemas } from '../testerSchema';
import { CompleteProfileModal } from './modal/CompleteProfileModal';
import TesterInfo from './TesterInfo';
import { getStepByFilledStep } from './utils/getStepByFilledStep';
import { CLOTHING_OR_BAG_OPTIONS, SHOE_OPTIONS } from './utils/options';

const getIds = (data) => data.map((item) => item.id);

const TesterInfoFormikWrapper = ({ toggleChangeAvatarModal }) => {
  const { user, loading: userLoading } = useAuthContext();
  const { countries } = useGeo();

  const [isLoadingStep, setIsLoadingStep] = useState(false);

  const [step, setStep] = useState(0);

  const [isOpenCompleteModal, setIsOpenCompleteModal] = useState(false);

  const stepsCount = useRef(null);

  const isLastStep = step === stepsCount.current;

  const { ErrorMessage } = useToasts();
  const [completeTesterProfile, { loading }] = useCompleteTesterProfile({
    isLastStep,
    setIsOpenCompleteModal,
  });

  const {
    email: emailData,
    firstName: fName,
    lastName: lName,
    tester,
    secondaryAddress,
    id,
  } = user;

  const getTesterInitialValues = () => ({
    email: emailData,
    firstName: fName || '',
    lastName: lName || '',
    secondaryAddress,
    ...profileDTO(tester),
    generalShoeSizes: getIds(tester.generalShoeSizes),
    runningShoeSizes: getIds(tester.runningShoeSizes),
    hikingShoeSizes: getIds(tester.hikingShoeSizes),
    sandalShoeSizes: getIds(tester.sandalShoeSizes),
    bottomSizes: getIds(tester.bottomSizes),
    topSizes: getIds(tester.topSizes),
    isWorkForAnOutdoorCompany: booleanToString(tester.isWorkForAnOutdoorCompany),
    isCallOrText: booleanToString(tester.isCallOrText),
    shipPreference: tester.shipPreference || 'FedEx/UPS',
    country: tester.country || countries.find((country) => country.id === '1'),
    isHaveDog: !!tester.isHaveDog,
    isRecreateWithKids: !!tester.isRecreateWithKids,
    agreementSigned: null,
  });

  const isClothingOrBagTypeGearSelected = tester?.typeGearWouldTest?.some((option) =>
    CLOTHING_OR_BAG_OPTIONS.includes(option),
  );
  const isShoeTypeGearSelected = tester?.typeGearWouldTest?.some((option) =>
    SHOE_OPTIONS.includes(option),
  );

  useEffect(() => {
    if (tester) {
      setIsLoadingStep(true);
      const data = getTesterInitialValues();
      const filledStep = getStepByFilledStep(
        data,
        isClothingOrBagTypeGearSelected,
        isShoeTypeGearSelected,
      );

      setStep(filledStep);
      setIsLoadingStep(false);
    }
  }, []);

  const handleUpdateProfile = (data, resetForm) =>
    completeTesterProfile({
      variables: {
        testerId: Number(id),
        input: data,
      },
      onError: () => {
        resetForm();
        ErrorMessage('Error. Please, try again.');
      },
    });

  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };

  const handlePrevState = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);

  const handleUpdateTester = async (data, resetForm) => {
    const {
      firstName,
      lastName,
      weight,
      age,
      birthdate,
      email,
      phoneNumber,
      country,
      state,
      otherActivities,
      heightMetric,
      heightImperialInch,
      heightImperialFeet,
      weightLbs,
      weightKgs,
      chestInches,
      chestCm,
      waistAtNavelInches,
      waistAtNavelCm,
      waistAtPantsInches,
      waistAtPantsCm,
      hipsInches,
      hipsCm,
      inseamInches,
      inseamCm,
      secondaryAddress: secAddress,
      ...testerInfo
    } = data;

    const formattedActivities = otherActivities?.map(({ title, ...item }) => ({
      ...item,
      name: title,
    }));

    const dataVariable = {
      firstName,
      lastName,
      email,
      testerInfo: {
        ...testerInfo,
        otherActivities: formattedActivities,
        weight: weight?.toString(),
        birthdate: birthdate ? dayjs(birthdate).format('YYYY-MM-DD') : null,
        countryId: country?.id,
        stateId: state?.id,
        phoneNumber: phoneNumber?.toString(),
        heightMetric: heightMetric?.toString(),
        heightImperialInch: heightImperialInch?.toString(),
        heightImperialFeet: heightImperialFeet?.toString(),
        weightLbs: weightLbs?.toString(),
        weightKgs: weightKgs?.toString(),
        chestInches: chestInches?.toString(),
        chestCm: chestCm?.toString(),
        waistAtNavelInches: waistAtNavelInches?.toString(),
        waistAtNavelCm: waistAtNavelCm?.toString(),
        waistAtPantsInches: waistAtPantsInches?.toString(),
        waistAtPantsCm: waistAtPantsCm?.toString(),
        hipsInches: hipsInches?.toString(),
        hipsCm: hipsCm?.toString(),
        inseamInches: inseamInches?.toString(),
        inseamCm: inseamCm?.toString(),
        isWorkForAnOutdoorCompany: stringToBoolean(testerInfo.isWorkForAnOutdoorCompany),
        isHaveDog: stringToBoolean(testerInfo.isHaveDog),
        isRecreateWithKids: stringToBoolean(testerInfo.isRecreateWithKids),
        isCallOrText: stringToBoolean(testerInfo.isCallOrText),
        occupation: testerInfo.occupation,
      },
    };

    await handleUpdateProfile(dataVariable, resetForm);
    if (!isLastStep) handleNextStep();
  };

  const toggleModal = () => {
    setIsOpenCompleteModal(true);
  };

  const getValidationSchema = () => {
    if (isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected) {
      const newArray = [...testerInfoSchemas.slice(0, 14), ...testerInfoSchemas.slice(16)];
      stepsCount.current = newArray.length - 1;
      return newArray[step];
    }
    if (!isClothingOrBagTypeGearSelected && isShoeTypeGearSelected) {
      const newArray = [...testerInfoSchemas.slice(0, 9), ...testerInfoSchemas.slice(14)];
      stepsCount.current = newArray.length - 1;
      return newArray[step];
    }
    if (!isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected) {
      const newArray = [...testerInfoSchemas.slice(0, 9), ...testerInfoSchemas.slice(16)];
      stepsCount.current = newArray.length - 1;
      return newArray[step];
    }
    stepsCount.current = testerInfoSchemas.length - 1;
    return testerInfoSchemas[step];
  };

  const isLoading = userLoading || isLoadingStep;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <RequestHandler loading={isLoading}>
        <Formik
          initialValues={getTesterInitialValues()}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => handleUpdateTester(values, resetForm)}
          validationSchema={getValidationSchema()}
          validateOnMount={true}
        >
          <Form sx={{ width: '100%' }}>
            <TesterInfo
              loading={loading}
              step={step}
              handlePrevState={handlePrevState}
              toggleChangeAvatarModal={toggleChangeAvatarModal}
              isClothingOrBagTypeGearSelected={isClothingOrBagTypeGearSelected}
              isShoeTypeGearSelected={isShoeTypeGearSelected}
            />
          </Form>
        </Formik>
      </RequestHandler>
      <CompleteProfileModal open={isOpenCompleteModal} toggleModal={toggleModal} />
    </Box>
  );
};

export default TesterInfoFormikWrapper;
