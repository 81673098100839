import { Box } from '@mui/material';
import { format } from 'date-fns';
import dayjs from 'dayjs';

export const stringToArray = (value) => (Array.isArray(value) ? value : [value]);

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const formatDate = (
  value,
  formatZone = 'en-029',
  options = {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
  },
) => {
  if (!value) return '';
  const date = dayjs(value).toDate();
  return date.toLocaleDateString(formatZone, options);
};

export const formatDateTime = (
  value,
  formatZone = 'en-029',
  options = {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  },
) => {
  if (!value) return '';
  const date = new Date(value);
  return date.toLocaleTimeString(formatZone, options);
};

export const formatDataEnUs = (value) => {
  if (!value) return '';
  const date = new Date(value);

  return format(date, 'MM/dd/yyyy');
};

export const formatDateAfNa = (value) => {
  if (!value) return '';
  const date = new Date(value);
  return date.toLocaleDateString('af-NA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const convertDateRangeToString = (range) => {
  const from = formatDateAfNa(range.from || range.startDate);
  const to = formatDateAfNa(range.to || range.endDate);
  return `${from}:${to}`;
};
export const convertStringToDateRange = (string) => {
  if (!string) return null;
  const splitted = string.split(':');

  return {
    from: formatDateAfNa(splitted[0]),
    to: formatDateAfNa(splitted[1]),
  };
};

export const parserHTMLToText = (htmlText) =>
  htmlText.replaceAll('<p>', '').replaceAll('</p>', '\n\n').replaceAll('<br>', '\n');

export const stringifyHTMLToText = (text) => {
  const tempText = `<p>${text.replaceAll('\n\n', '</p><p>').replaceAll('\n', '<br>')}`;
  return tempText.slice(0, tempText.length - 3);
};

export const capitalizeFirstLetter = (string) => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

// export const booleanToString = (val) => {
//   if (val === false) return 'false';
//   return val ? 'true' : 'none';
// };

export const booleanToString = (val) => {
  if (val === false || val === 'false') return 'false';
  if (!val || val === 'none') return 'none';
  return 'true';
};

export const stringToBoolean = (val) => {
  if (val === 'none') return null;
  return val === 'true' || val === true;
};

export const toSelectValues = (array = [], { titleKey, valueKey } = {}) => {
  if (titleKey && valueKey) {
    return array.reduce((acc, next) => {
      if (!next[titleKey] || !next[valueKey]) {
        return acc;
      }
      acc.push({
        title: next[titleKey],
        value: next[valueKey],
      });
      return acc;
    }, []);
  }

  return array.reduce((acc, next) => {
    if (!next) return acc;
    acc.push({
      title: next,
      value: next,
    });
    return acc;
  }, []);
};

export const convertLbsToKg = (lbs) => {
  if (!lbs) {
    return lbs;
  }
  return Math.round(lbs * 0.45359237);
};

export const convertKgToLbs = (kg) => {
  if (!kg) {
    return kg;
  }
  return Math.round(kg / 0.45359237);
};

export const convertFeetInchesToCm = ({ feet, inches }) => {
  if (!feet && !inches) return inches;

  return Math.round(((feet || 0) * 12 + Number(inches)) * 2.54);
};

export const convertCmToFeetInches = ({ cm, inInches = false }) => {
  if (Number.isNaN(Number.parseFloat(cm))) {
    const defaultResult = {
      feet: '',
      inches: '',
    };
    return inInches ? '' : defaultResult;
  }

  const inches = cm / 2.54;

  if (inInches) {
    return Math.round(inches);
  }

  return {
    feet: Math.round(inches / 12),
    inches: Math.round(inches % 12),
  };
};

export const convertMultipleInchesToCm = ({ inches }) => {
  const inchesArray = inches.split(',');
  const cmArray = inchesArray.map((item) =>
    convertFeetInchesToCm({
      inches: item,
    }),
  );
  return cmArray.join(',');
};

export const convertMultipleCmToInches = ({ cm }) => {
  const cmArray = cm.split(',');
  const inchesArray = cmArray.map((item) =>
    convertCmToFeetInches({
      cm: item,
      inInches: true,
    }),
  );
  return inchesArray.join(',');
};

export const handleSetMultipleValues = (setValue) => (e) => {
  setValue(
    e.target.name,
    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
  );
};

export const handleShoeSizeValueOrFallback =
  (bottomSizeOptions, fallback = 'none') =>
  (ids) => {
    const options = Object.values(bottomSizeOptions)
      .flat()
      .filter(Boolean)
      .reduce((acc, val) => (ids.includes(val.id) ? [...acc, val.code] : acc), [])
      .map((item, i) => {
        if (i === ids.length - 1) return item;
        return `${item}, `;
      });

    return options?.length ? options : <Box color='#444'>{fallback}</Box>;
  };

export const handleShoeSizeValue = (bottomSizeOptions) => (ids) => {
  // формується масив з розмірами (['W1', 'M1']) залежно від ids (['10', '11']);
  return Object.values(bottomSizeOptions)
    .flat()
    .filter(Boolean)
    .reduce((acc, val) => (ids.includes(val.id) ? [...acc, val.code] : acc), [])
    .map((item, i) => {
      if (i === ids.length - 1) return item;
      return `${item}, `;
    });
  // потрібно повернути обо'язково масив зі значеннями, так як виколистовується multiple select;
};

export const handleCommaSeparatedValuesOrNone = (val, placeholder = 'none') => {
  if (val?.length) {
    return val?.map((item, i) => {
      if (i === val.length - 1) return item;
      return `${item}, `;
    });
  }
  return <Box color='#444444'>{placeholder}</Box>;
};

export const handleCommaSeparatedValues = (val) => {
  return val?.map((item, i) => {
    if (i === val.length - 1) return item;
    return `${item}, `;
  });
};

export const numberOrNull = (input) => {
  return Number.parseFloat(input) || null;
};

export const prepareFilterData = (data) => {
  const { ageFrom, ageTo, ...rest } = data;

  return {
    ...rest,
    ageRange: {
      from: numberOrNull(ageFrom),
      to: numberOrNull(ageTo),
    },
  };
};
