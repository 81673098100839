import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const DoForALiving = () => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext();

  return (
    <ContentWrapper label='Tell us what you do for a living'>
      <StyledLabel label='What is your profession?' sx={testerInfoStyle.label}>
        <TextField
          size='small'
          name='occupation'
          onInput={handleChange}
          value={values.occupation}
          error={touched.occupation && !!errors.occupation}
          helperText={touched.occupation && errors.occupation}
          placeholder='Enter your profession'
          onBlur={handleBlur}
        />
      </StyledLabel>
    </ContentWrapper>
  );
};

export default DoForALiving;
