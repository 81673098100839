import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';

import ContentWrapper from '../components/ContentWrapper';
import OptionItemBordered from '../components/OptionItemBordered';
import { TYPE_GEAR_WOULD_TEST_OPTIONS } from '../utils/options';

const Gear = () => {
  const { setFieldValue, values } = useFormikContext();

  const handleTypeSelect = (type) => () => {
    if (values?.typeGearWouldTest?.includes(type)) {
      const filteredOptions = values.typeGearWouldTest.filter((gear) => gear !== type);
      setFieldValue('typeGearWouldTest', filteredOptions);
    } else if (values?.typeGearWouldTest?.length) {
      setFieldValue('typeGearWouldTest', [...values.typeGearWouldTest, type]);
    } else {
      setFieldValue('typeGearWouldTest', [type]);
    }
  };

  return (
    <ContentWrapper
      label='What gear would you like to test?'
      subLabel='Now for the fun part. Select all that apply.'
    >
      {TYPE_GEAR_WOULD_TEST_OPTIONS.map(({ title, options }) => (
        <Box key={title}>
          <StyledLabel label={title} mb={2} sx={{ marginBottom: '20px' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
              {options?.map((type) => {
                const isSelected = values?.typeGearWouldTest?.includes(type);
                return (
                  <OptionItemBordered
                    key={type}
                    title={type}
                    value={type}
                    isSelected={isSelected}
                    onClick={handleTypeSelect}
                  />
                );
              })}
            </Box>
          </StyledLabel>
        </Box>
      ))}
    </ContentWrapper>
  );
};

export default Gear;
