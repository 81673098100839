import { Box, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { Ages } from 'src/constants/constants';
import { useGetTesterValues } from 'src/hooks';

import { testerInfoStyle } from '../styles';
import ContentByStep from './ContentByStep';

const TesterInfo = ({
  toggleChangeAvatarModal,
  step,
  handlePrevState,
  isShoeTypeGearSelected,
  isClothingOrBagTypeGearSelected,
  loading,
}) => {
  const { isValid, errors, validateForm, setTouched } = useFormikContext();

  const testerPossibleValues = useGetTesterValues();

  useEffect(() => {
    validateForm();
    setTouched({}, false);
  }, [step, validateForm]);

  const possibleValues = useMemo(() => {
    return (
      {
        ...testerPossibleValues,
        Ages,
      } || {}
    );
  }, [testerPossibleValues]);

  const isDisabled = !isValid || Object.keys(errors).length > 0 || loading;

  return (
    <Box sx={testerInfoStyle.root}>
      <ContentByStep
        step={step}
        toggleChangeAvatarModal={toggleChangeAvatarModal}
        possibleValues={possibleValues}
        isClothingOrBagTypeGearSelected={isClothingOrBagTypeGearSelected}
        isShoeTypeGearSelected={isShoeTypeGearSelected}
      />

      <Box sx={testerInfoStyle.buttonWrapper}>
        {step !== 0 && (
          <Button
            type='button'
            variant='outlined'
            sx={testerInfoStyle.previousBtn}
            onClick={handlePrevState}
          >
            Previous
          </Button>
        )}
        <Button
          variant='contained'
          sx={testerInfoStyle.nextBtn}
          type='submit'
          disabled={isDisabled}
        >
          {step === 19 ? 'Finish profile' : 'Next'}
        </Button>
      </Box>
    </Box>
  );
};

export default TesterInfo;
