import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { ChestModal } from 'src/components/testerDetail/tabs/DetailsTab/modal/ChestModal';
import { theme } from 'src/theme';
import { convertCmToFeetInches, convertFeetInchesToCm } from 'src/utils/helpers';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const ChestMeasurement = () => {
  const { handleChange, values, errors, touched, handleBlur, setFieldValue } = useFormikContext();
  const [open, toggleModal] = useState(false);

  const handleChangeInchesToCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertFeetInchesToCm({
        inches: e.target.value,
      }),
    );
  };
  const handleChangeCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertCmToFeetInches({
        cm: e.target.value,
        inInches: true,
      }),
    );
  };

  const handleToggleModal = () => {
    toggleModal((prev) => !prev);
  };

  return (
    <ContentWrapper label='Chest Measurement'>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 1 }}>
        <Typography
          sx={{
            fontSize: '16px',
          }}
        >
          Chest size
        </Typography>
        <Button
          sx={{ color: theme.colors.tertiary, padding: 0, fontSize: '14px', lineHeight: '20px' }}
          onClick={handleToggleModal}
        >
          How to measure?
        </Button>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
        <StyledLabel label='Imperial (US)' sx={testerInfoStyle.label}>
          <TextField
            type='number'
            size='small'
            name='chestInches'
            onInput={handleChangeInchesToCm('chestCm')}
            value={values.chestInches}
            error={touched.chestInches && !!errors.chestInches}
            helperText={touched.chestInches && errors.chestInches}
            placeholder='Inches'
            onBlur={handleBlur}
          />
        </StyledLabel>
        <StyledLabel label='Metric' sx={testerInfoStyle.label}>
          <TextField
            type='number'
            size='small'
            name='chestCm'
            onInput={handleChangeCm('chestInches')}
            value={values.chestCm}
            error={touched.chestCm && !!errors.chestCm}
            helperText={touched.chestCm && errors.chestCm}
            placeholder='cm'
            onBlur={handleBlur}
          />
        </StyledLabel>
      </Box>
      <ChestModal open={open} toggleModal={handleToggleModal} />
    </ContentWrapper>
  );
};

export default ChestMeasurement;
