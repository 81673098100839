import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { theme } from 'src/theme';

export const RequiredFieldModal = ({ open, toggleModal, isGear }) => {
  const onClose = () => {
    toggleModal();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      componentsProps={{
        backdrop: {
          sx: { backgroundColor: 'transparent' },
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: theme.colors.neutral.white,
          boxShadow: 24,
          padding: '24px',
          borderRadius: '8px',
          minWidth: 300,
          maxWidth: '321px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            bgcolor: theme.colors.neutral.black,
            padding: '24px',
            borderRadius: '8px',
          }}
        >
          <Typography
            id='modal-title'
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              color: theme.colors.neutral.white,
              textAlign: 'center',
            }}
          >
            {isGear
              ? 'Choose at least one activity'
              : 'An input is required here. You’re unable to leave it empty.'}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '28px',
            }}
          >
            <Button
              onClick={onClose}
              variant='contained'
              sx={{
                borderRadius: '4px',
                px: 4,
                py: 1,
                fontSize: '14px',
                lineHeight: '20px',
                bgcolor: theme.colors.neutral.white,
                color: theme.colors.neutral.black,
                '&:hover': {
                  bgcolor: theme.colors.neutral.light.normal,
                },
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
