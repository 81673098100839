import { instanceOf } from 'prop-types';
import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { UserRoles } from 'src/constants/constants';
import { useCurrentUserQuery } from 'src/hooks';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const PrivateRoute = ({ route, path }) => {
  const { user, loading } = useCurrentUserQuery();

  const Component = route.component;
  const Layout = route.layout || Fragment;
  const token = cookies.get('token');

  if (loading) return null;

  if (!user || !token) return <Navigate to='/login' />;

  if (
    (path === '/' || path === 'tests' || path === 'profile') &&
    user.role === UserRoles.TESTER &&
    user.tester.testerStatusExternal === 'INCOMPLETE'
  ) {
    return <Navigate to='/welcome' />;
  }

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

PrivateRoute.propTypes = {
  route: instanceOf(Object),
  user: instanceOf(Object),
};

export default PrivateRoute;
