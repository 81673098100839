import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { useEffect } from 'react';
import { DetailLabel, DetailSelect } from 'src/components';
import { ActivitiesTypeGear } from 'src/components/testerDetail/tabs/ActivitiesTab/ActivitiesTypeGear';

import { ACTIVITY_FIELDS } from '../TesterInfo/utils/options';
import { styles } from './styles';

export const Activities = ({
  activities = [],
  otherActivities = [],
  frequencyValues = [],
  handleChange,
  handleSubmit,
  possibleValues,
  values = {},
  toggleModal = null,
  setIsGear,
}) => {
  const isOneSelected = ACTIVITY_FIELDS.some((field) => {
    return values[field] !== 'NEVER';
  });

  useEffect(() => {
    if (!isOneSelected) {
      toggleModal();
      setIsGear(true);
    }
  }, [isOneSelected]);

  return (
    <Box>
      <Box sx={styles.requiredWrapper}>
        <span>*</span> Required
      </Box>

      <ActivitiesTypeGear
        possibleValues={possibleValues}
        submitOnValueChange={false}
        label={
          <Box sx={styles.required}>
            What type of gear would you like to test? <span>*</span>
          </Box>
        }
        toggleModal={toggleModal}
      />
      {activities.map(({ title, value }) => (
        <DetailLabel label={title} key={value}>
          <DetailSelect
            label={title.toLowerCase()}
            name={value}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            value={values[value]}
            possibleValues={frequencyValues}
            submitOnValueChange
            placeholder={title}
            isOneSelected={isOneSelected}
          />
        </DetailLabel>
      ))}
      {otherActivities.map(({ title, value }, index) => (
        <DetailLabel label={title} key={value}>
          <DetailSelect
            label={title.toLowerCase()}
            name={`otherActivities.${index}.value`}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            value={values.otherActivities?.[index].value}
            possibleValues={frequencyValues}
          />
        </DetailLabel>
      ))}
    </Box>
  );
};

Activities.propTypes = {
  activities: instanceOf(Array),
  otherActivities: instanceOf(Array),
  frequencyValues: instanceOf(Array),
  handleChange: func,
  handleSubmit: func,
  values: instanceOf(Object),
};
