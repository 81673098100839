import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { Flex, StyledLabel, StyledSelect } from 'src/components/common/CommonStyledComponents';
import { CheckIcon } from 'src/components/UI/icons/CheckIcon';
import { months, years } from 'src/constants/constants';
import { theme } from 'src/theme';
import { daysInMonth, getValueDay } from 'src/utils/daysInMonth';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';
import { stepsStyles } from '../styles';

const About = ({ genderPossibleValues }) => {
  const { handleChange, values, errors, touched, handleBlur, setFieldValue } = useFormikContext();

  const handleGenderChange = (value) => () => {
    const name = 'gender';

    handleChange({
      target: {
        name,
        value,
      },
    });
  };

  const renderMonthValue = (val) => {
    if (val.length) {
      return months.find((item) => item.value === val).label;
    }
    return val;
  };

  const handleMonth = (e) => {
    const index = months.findIndex((item) => item.value === e.target.value);

    let newDate;

    if (values.birthdate) {
      newDate = dayjs(values.birthdate).set('month', index).toDate();
    } else {
      newDate = dayjs().set('month', index).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'yyyy-MM-dd'));
  };

  const handleDay = (e) => {
    let newDate;

    if (values.birthdate) {
      newDate = dayjs(values.birthdate).set('date', e.target.value).toDate();
    } else {
      newDate = dayjs().set('date', e.target.value).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'yyyy-MM-dd'));
  };

  const handleYear = (e) => {
    let newDate;

    if (values.birthdate) {
      newDate = dayjs(values.birthdate).set('year', e.target.value).toDate();
    } else {
      newDate = dayjs().set('year', e.target.value).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'yyyy-MM-dd'));
  };

  return (
    <ContentWrapper label='About' subLabel='Tell us a little about yourself.'>
      <StyledLabel label='Gender' sx={testerInfoStyle.label}>
        {genderPossibleValues?.map((value) => (
          <MenuItem
            key={value}
            value={value}
            sx={stepsStyles.selectItem}
            onClick={handleGenderChange(value)}
          >
            <Box className='check-icon' sx={stepsStyles.checkIcon}>
              <CheckIcon isChecked={values.gender === value} />
            </Box>
            {value}
          </MenuItem>
        ))}
      </StyledLabel>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        <StyledLabel label='When is your birthday?' sx={testerInfoStyle.label}>
          <Flex gap={1}>
            <StyledSelect
              name='birthdate'
              onChange={handleMonth}
              error={!!errors.birthdate}
              helperText={errors.birthdate}
              placeholder='MM'
              value={values.birthdate && dayjs(values.birthdate).get('month').toString()}
              renderValue={renderMonthValue}
            >
              {months.map((item) => (
                <MenuItem
                  value={item.value}
                  key={item.option}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {item.option}
                </MenuItem>
              ))}
            </StyledSelect>
            <StyledSelect
              name='birthdate'
              onChange={handleDay}
              error={!!errors.birthdate}
              helperText={!!errors.birthdate}
              placeholder='DD'
              value={getValueDay(values.birthdate)}
            >
              {daysInMonth(values.birthdate).map((date) => (
                <MenuItem
                  value={date}
                  key={date}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {date}
                </MenuItem>
              ))}
            </StyledSelect>
            <StyledSelect
              name='birthdate'
              onChange={handleYear}
              error={!!errors.birthdate}
              helperText={!!errors.birthdate}
              placeholder='YYYY'
              value={dayjs(values.birthdate).get('year')}
            >
              {years.map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </StyledSelect>
          </Flex>
        </StyledLabel>
      </Box>
      <StyledLabel label='In your own words...' sx={testerInfoStyle.label}>
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1.33,
            marginBottom: '8px',
            color: theme.colors.neutral.dark.mid,
          }}
        >
          We're interested in learning more about your outdoor interests and activities, your
          hobbies, your living environment (urban or rural), and any other details that will help us
          to select appropriate products for you to test.
        </Typography>
        <TextField
          size='small'
          name='relatesOutdoorProductTestingDescription'
          onInput={handleChange}
          value={values.relatesOutdoorProductTestingDescription}
          error={
            touched.relatesOutdoorProductTestingDescription &&
            !!errors.relatesOutdoorProductTestingDescription
          }
          helperText={
            touched.relatesOutdoorProductTestingDescription &&
            errors.relatesOutdoorProductTestingDescription
          }
          onBlur={handleBlur}
          multiline
          placeholder='Tell us about yourself'
          InputProps={{
            sx: {
              minHeight: '151px',
              pb: 10,
              alignItems: 'start',
              fontSize: 14,
            },
          }}
        />
      </StyledLabel>
    </ContentWrapper>
  );
};

export default About;
