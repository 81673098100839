import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel, DetailSelect, LinkOut } from 'src/components';
import { DetailDatePicker } from 'src/components/UI/detail/DetailDatePicker';
import { rightPanel } from 'src/styles';

import { styles } from './styles';

export const Overview = ({ values, handleChange, possibleValues, setFieldError }) => (
  <Box>
    <DetailLabel label='Status - Internal'>
      <DetailSelect
        label='Status - Internal'
        name='testerStatusInternal'
        handleChange={handleChange}
        value={values.testerStatusInternal}
        possibleValues={possibleValues.testerStatusInternals}
        submitOnValueChange={true}
      />
    </DetailLabel>
    <DetailLabel label='Group'>
      <DetailSelect
        label='Group'
        name='testerGroup'
        handleChange={handleChange}
        value={values.testerGroup}
        possibleValues={possibleValues.testerGroups}
        submitOnValueChange={true}
      />
    </DetailLabel>
    <DetailLabel label='Status - External'>
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
        }}
      >
        {values.testerStatusExternal.charAt(0) + values.testerStatusExternal.slice(1).toLowerCase()}
      </Typography>
    </DetailLabel>
    <DetailLabel label='Test Frequency'>
      <DetailSelect
        label='Test Frequency'
        name='participationInTests'
        handleChange={handleChange}
        value={values.participationInTests}
        possibleValues={possibleValues.participationInTests}
        submitOnValueChange={true}
        placeholder='Select frequency'
      />
    </DetailLabel>
    <DetailLabel label='Notes'>
      <DetailInput
        name='notesInternal'
        handleChange={handleChange}
        value={values.notesInternal}
        multiline
        submitOnValueChange={true}
        textStyle={{
          whiteSpace: 'break-spaces',
        }}
      />
    </DetailLabel>
    <DetailLabel label='Agreement Signed'>
      <DetailDatePicker
        sx={rightPanel.select}
        name='agreementSigned'
        setFieldError={setFieldError}
        handleChange={handleChange}
        value={values.agreementSigned}
        hideLabel
        submitOnValueChange={true}
      />
    </DetailLabel>
    <DetailLabel label='Agreement Link'>
      <DetailInput
        name='agreementLink'
        handleChange={handleChange}
        value={values.agreementLink}
        adornment={
          <Box sx={styles.linkIcon}>
            <LinkOut />
          </Box>
        }
        textStyle={styles.agreementLink}
        submitOnValueChange={true}
      />
    </DetailLabel>
  </Box>
);

Overview.propTypes = {
  handleChange: func,
  values: instanceOf(Object),
  possibleValues: instanceOf(Object),
  setFieldError: func,
};
