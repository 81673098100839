export const stepsStyles = {
  shipPreferenceSelect: {
    '.MuiMenuItem-root .check-icon': {
      display: 'initial !important',
    },
    '&.MuiInputBase-root': {
      '& > div[aria-expanded=true]': {
        '& ~ div .chevron-down-icon': {
          transform: 'rotate(180deg)',
        },
      },
      '&.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '0px',
        '& .MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':
          {
            padding: '10px 12px',
            position: 'absolute',
            boxSizing: 'border-box',
          },
      },
      '& .chevron-down-icon': {
        margin: '0px 14px 0px 9px',
      },
    },
  },
  activitiesSelect: {
    '.MuiMenuItem-root .check-icon': {
      display: 'initial !important',
    },
    '&.MuiInputBase-root': {
      minWidth: '312px',
      '& > div[aria-expanded=true]': {
        '& ~ div .chevron-down-icon': {
          transform: 'rotate(180deg)',
        },
      },
      '&.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '0px',
        '& .MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':
          {
            padding: '10px 12px',
            position: 'absolute',
            boxSizing: 'border-box',
          },
      },
      '& .chevron-down-icon': {
        margin: '0px 14px 0px 9px',
      },
    },
  },
  addressSelectWrapper: {
    '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root':
      {
        fontSize: '14px',
        padding: '10px 12px',
        '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
          padding: '0px',
        },
      },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
      {
        padding: '0px',
        marginRight: '2px',
        '&:hover': {
          background: 'transparent',
        },
        '& > div': {
          display: 'flex',
        },
      },
  },
  textFieldWrapper: {
    '& .MuiInputBase-root.MuiOutlinedInput-root input': {
      padding: '10px 12px',
      fontSize: '14px',
    },
  },
  selectItem: {
    width: '100%',
    padding: '0 12px 0 8px',
    height: 28,
    boxSizing: 'border-box',
    justifyContent: 'flex-start',
    '&.Mui-selected': {
      backgroundColor: 'transparent !important',
    },
    fontSize: 14,
    textTransform: 'capitalize',
  },
  checkIcon: {
    maxWidth: 'min-content',
    marginRight: 2,
  },
  userAgreementList: {
    padding: 0,
    marginBottom: '12px',
  },
  userAgreementListItem: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '8px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 0,
    pointerEvents: 'none',
    cursor: 'default',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    padding: 0,
    '&::before': {
      content: '"•"',
      fontSize: '1.2em',
    },
  },
};
