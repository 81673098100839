import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';

import ContentWrapper from '../components/ContentWrapper';

const AdditionalFootwearInfo = () => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext();

  return (
    <ContentWrapper
      label='Additional Footwear Info'
      subLabel='Tell us about any fit issues that you encounter (narrow heel, bunions, etc.) when trying
          on shoes.'
    >
      <TextField
        size='small'
        name='shoeSizeAdditionalInfo'
        onInput={handleChange}
        value={values.shoeSizeAdditionalInfo}
        error={touched.shoeSizeAdditionalInfo && !!errors.shoeSizeAdditionalInfo}
        helperText={touched.shoeSizeAdditionalInfo && errors.shoeSizeAdditionalInfo}
        onBlur={handleBlur}
        multiline
        placeholder='Add additional footwear info'
        sx={{ width: '100%', marginBottom: 3 }}
        InputProps={{
          sx: {
            width: '100%',
            minHeight: '151px',
            pb: 10,
            alignItems: 'start',
            fontSize: 14,
          },
        }}
      />
    </ContentWrapper>
  );
};

export default AdditionalFootwearInfo;
