import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFormikContext } from 'formik';
import React from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { theme } from 'src/theme';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';
import OptionItemBordered from '../components/OptionItemBordered';

const BottomSize = ({ bottomSizePossibleValues }) => {
  const { setFieldValue, values } = useFormikContext();

  const man = bottomSizePossibleValues?.man;
  const woman = bottomSizePossibleValues?.woman;
  const manUS = bottomSizePossibleValues?.manUS;
  const womanUS = bottomSizePossibleValues?.womanUS;

  const handleSizeSelect = (id) => () => {
    if (values?.bottomSizes?.includes(id)) {
      const filteredOptions = values.bottomSizes.filter((sizeId) => sizeId !== id);
      setFieldValue('bottomSizes', filteredOptions);
    } else {
      setFieldValue('bottomSizes', [...values.bottomSizes, id]);
    }
  };

  return (
    <ContentWrapper
      label='Bottom Size'
      subLabel='What size bottoms do you usually wear? We’re talking waist sizes here.'
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            fontSize: '12px',
            lineHeight: 1.33,
            fontWeight: '400',
            marginBottom: '8px',
            color: theme.colors.neutral.dark.gray,
          }}
        >
          Select all that apply
        </Typography>
        <StyledLabel label='Women' mb={2} sx={testerInfoStyle.label}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {woman?.map((size) => {
              const isSelected = values.bottomSizes?.includes(size.id);
              return (
                <OptionItemBordered
                  key={size.id}
                  title={size.name}
                  value={size.id}
                  isSelected={isSelected}
                  onClick={handleSizeSelect}
                />
              );
            })}
          </Box>
          <Typography
            sx={{
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 700,
              marginBottom: 1,
              marginTop: 1,
            }}
          >
            US
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {womanUS?.map((size) => {
              const isSelected = values.bottomSizes?.includes(size.id);
              return (
                <OptionItemBordered
                  key={size.id}
                  title={size.name}
                  value={size.id}
                  isSelected={isSelected}
                  onClick={handleSizeSelect}
                />
              );
            })}
          </Box>
        </StyledLabel>
        <StyledLabel label='Men' mb={2} sx={testerInfoStyle.label}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {man?.map((size) => {
              const isSelected = values.bottomSizes?.includes(size.id);
              return (
                <OptionItemBordered
                  key={size.id}
                  title={size.name}
                  value={size.id}
                  isSelected={isSelected}
                  onClick={handleSizeSelect}
                />
              );
            })}
          </Box>
          <Typography
            sx={{
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 700,
              marginBottom: 1,
              marginTop: 1,
            }}
          >
            US
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {manUS?.map((size) => {
              const isSelected = values.bottomSizes?.includes(size.id);
              return (
                <OptionItemBordered
                  key={size.id}
                  title={size.name}
                  value={size.id}
                  isSelected={isSelected}
                  onClick={handleSizeSelect}
                />
              );
            })}
          </Box>
        </StyledLabel>
      </Box>
    </ContentWrapper>
  );
};

export default BottomSize;
