import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { HipsModal } from 'src/components/testerDetail/tabs/DetailsTab/modal/HipsModal';
import { WaistAtNavelModal } from 'src/components/testerDetail/tabs/DetailsTab/modal/WaistAtNavelModal';
import { WaistAtPantsModal } from 'src/components/testerDetail/tabs/DetailsTab/modal/WaistAtPantsModal';
import { theme } from 'src/theme';
import { convertCmToFeetInches, convertFeetInchesToCm } from 'src/utils/helpers';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const WaistAndHipsMeasurements = () => {
  const { handleChange, values, errors, touched, handleBlur, setFieldValue } = useFormikContext();
  const [open, toggleModal] = useState(false);
  const [waistAtPantsOpen, WaistAtPantsToggleModal] = useState(false);
  const [openHips, hipsToggleModal] = useState(false);

  const handleChangeInchesToCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertFeetInchesToCm({
        inches: e.target.value,
      }),
    );
  };
  const handleChangeCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertCmToFeetInches({
        cm: e.target.value,
        inInches: true,
      }),
    );
  };

  const handleToggleModal = () => {
    toggleModal((prev) => !prev);
  };

  const handleWaistAtPantsToggleModal = () => {
    WaistAtPantsToggleModal((prev) => !prev);
  };
  const handleHipsToggleModal = () => {
    hipsToggleModal((prev) => !prev);
  };

  return (
    <ContentWrapper label='Waist and Hips Measurements'>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: '4px' }}>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '700',
          }}
        >
          Waist at Navel
        </Typography>
        <Button
          sx={{
            color: theme.colors.tertiary,
            padding: 0,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '700',
          }}
          onClick={handleToggleModal}
        >
          How to measure?
        </Button>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 3, marginBottom: 2 }}>
        <StyledLabel label='Imperial (US)' sx={{ ...testerInfoStyle.label }}>
          <TextField
            type='number'
            size='small'
            name='waistAtNavelInches'
            onInput={handleChangeInchesToCm('waistAtNavelCm')}
            value={values.waistAtNavelInches}
            error={touched.waistAtNavelInches && !!errors.waistAtNavelInches}
            helperText={touched.waistAtNavelInches && errors.waistAtNavelInches}
            placeholder='Inches'
            onBlur={handleBlur}
          />
        </StyledLabel>
        <StyledLabel label='Metric' sx={{ ...testerInfoStyle.label }}>
          <TextField
            type='number'
            size='small'
            name='waistAtNavelCm'
            onInput={handleChangeCm('waistAtNavelInches')}
            value={values.waistAtNavelCm}
            error={touched.waistAtNavelCm && !!errors.waistAtNavelCm}
            helperText={touched.waistAtNavelCm && errors.waistAtNavelCm}
            placeholder='cm'
            onBlur={handleBlur}
          />
        </StyledLabel>
      </Box>
      <Box
        sx={{ width: '100%', display: 'flex', gap: 2, alignItems: 'center', marginBottom: '4px' }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '700',
          }}
        >
          Waist at Pants
        </Typography>
        <Button
          sx={{
            color: theme.colors.tertiary,
            padding: 0,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '700',
          }}
          onClick={handleWaistAtPantsToggleModal}
        >
          How to measure?
        </Button>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 3, marginBottom: 2 }}>
        <StyledLabel label='Imperial (US)' sx={{ ...testerInfoStyle.label }}>
          <TextField
            type='number'
            size='small'
            name='waistAtPantsInches'
            onInput={handleChangeInchesToCm('waistAtPantsCm')}
            value={values.waistAtPantsInches}
            error={touched.waistAtPantsInches && !!errors.waistAtPantsInches}
            helperText={touched.waistAtPantsInches && errors.waistAtPantsInches}
            placeholder='Inches'
            onBlur={handleBlur}
          />
        </StyledLabel>
        <StyledLabel label='Metric' sx={{ ...testerInfoStyle.label }}>
          <TextField
            type='number'
            size='small'
            name='waistAtPantsCm'
            onInput={handleChangeCm('waistAtPantsInches')}
            value={values.waistAtPantsCm}
            error={touched.waistAtPantsCm && !!errors.waistAtPantsCm}
            helperText={touched.waistAtPantsCm && errors.waistAtPantsCm}
            placeholder='cm'
            onBlur={handleBlur}
          />
        </StyledLabel>
      </Box>
      <Box
        sx={{ width: '100%', display: 'flex', gap: 2, alignItems: 'center', marginBottom: '4px' }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '700',
          }}
        >
          Hips
        </Typography>
        <Button
          sx={{
            color: theme.colors.tertiary,
            padding: 0,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '700',
          }}
          onClick={handleHipsToggleModal}
        >
          How to measure?
        </Button>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
        <StyledLabel label='Imperial (US)' sx={{ ...testerInfoStyle.label }}>
          <TextField
            type='number'
            size='small'
            name='hipsInches'
            onInput={handleChangeInchesToCm('hipsCm')}
            value={values.hipsInches}
            error={touched.hipsInches && !!errors.hipsInches}
            helperText={touched.hipsInches && errors.hipsInches}
            placeholder='Inches'
            onBlur={handleBlur}
          />
        </StyledLabel>
        <StyledLabel label='Metric' sx={{ ...testerInfoStyle.label }}>
          <TextField
            type='number'
            size='small'
            name='hipsCm'
            onInput={handleChangeCm('hipsInches')}
            value={values.hipsCm}
            error={touched.hipsCm && !!errors.hipsCm}
            helperText={touched.hipsCm && errors.hipsCm}
            placeholder='cm'
            onBlur={handleBlur}
          />
        </StyledLabel>
      </Box>
      <WaistAtNavelModal open={open} toggleModal={handleToggleModal} />
      <WaistAtPantsModal open={waistAtPantsOpen} toggleModal={handleWaistAtPantsToggleModal} />
      <HipsModal open={openHips} toggleModal={handleHipsToggleModal} />
    </ContentWrapper>
  );
};

export default WaistAndHipsMeasurements;
