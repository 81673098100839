import { Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { ChevronDown } from 'src/assets/icons';
import { DetailSelect } from 'src/components/UI';

import ContentWrapper from '../components/ContentWrapper';
import { stepsStyles } from '../styles';

const Activities = ({ activities, frequencyValues }) => {
  const { handleChange, values } = useFormikContext();

  const getTitle = (title) => {
    if (title === 'Kayaking/Canoeing/Rafting') return 'Kayaking/ Canoeing/ Rafting';
    return title;
  };

  return (
    <ContentWrapper
      label='Activities'
      subLabel='How often do you participate in the following activities?'
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 3 }}>
        {activities?.map(({ title, value }) => (
          <Box
            key={title}
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '700',
              }}
            >
              {getTitle(title)}
            </Typography>
            <DetailSelect
              name={value}
              handleChange={handleChange}
              value={values[value]}
              possibleValues={frequencyValues}
              placeholder={title}
              iconComponent={() => (
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <ChevronDown />
                </Box>
              )}
              alwaysShown
              defaultOpen={false}
              selectField={stepsStyles.activitiesSelect}
              sx={{ minWidth: '312px' }}
            />
          </Box>
        ))}
      </Box>
    </ContentWrapper>
  );
};

export default Activities;
