/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  GET_ADMIN_BY_ID,
  GET_ADMINS,
  GET_CUSTOMERS,
  GET_TESTERS,
  GET_TESTS,
} from 'src/graphql/queries';
import { useAuthContext, useGetUserById } from 'src/hooks';
import { useToasts } from 'src/hooks/useToasts';
import Cookies from 'universal-cookie';

import * as MUTATIONS from '../graphql/mutations';
import { useGetCustomers, useGetTest, useGetTestersQuery, useGetTests } from './useQueries';

const cookies = new Cookies();

export const useSignIn = () => {
  const { handleSuccessAuth } = useAuthContext();
  const navigate = useNavigate();

  return useMutation(MUTATIONS.SIGN_IN, {
    onCompleted: async ({ signIn }) => {
      await cookies.set('token', signIn.token, {
        path: '/',
        maxAge: 86400,
      });
      handleSuccessAuth();
      navigate('/tests');
    },
  });
};
export const useResetPassword = () => useMutation(MUTATIONS.RESET_PASSWORD);
export const useSetNewPassword = () => useMutation(MUTATIONS.SET_NEW_PASSWORD);

export const useAddTester = () => {
  const [mutation] = useMutation(MUTATIONS.ADD_TESTER, {
    refetchQueries: [GET_TESTERS],
  });
  const { SuccessMessage, ErrorMessage } = useToasts();

  return ({ input }) =>
    mutation({
      variables: {
        input,
      },
      onCompleted: ({ addTester }) => {
        if (!addTester?.status) return new Error();
        return SuccessMessage('Tester added');
      },
      onError: () => ErrorMessage('Error! Please, try again'),
    });
};

export const useEditTester = (options) => {
  const { ErrorMessage } = useToasts();

  return useMutation(MUTATIONS.EDIT_TESTER, {
    onError: () => ErrorMessage('Error. Please, try again.'),
    ...options,
  });
};
export const useAddCustomer = () => {
  const [mutation, { loading }] = useMutation(MUTATIONS.ADD_CUSTOMER);
  const { addToast } = useToasts();
  const { refetch } = useGetCustomers();

  const addCustomer = ({ input }) =>
    mutation({
      variables: {
        input,
      },
      onCompleted(res) {
        if (!res.addCustomer?.status) return new Error();

        addToast({
          message: 'Customer was added',
          variant: 'success',
          timer: 6000,
        });
        refetch();

        return true;
      },
      onError() {
        addToast({
          message: 'Email has already been used.',
          variant: 'error',
          timer: 6000,
        });
      },
    });

  return {
    addCustomer,
    loading,
  };
};
export const useReInviteUser = () => useMutation(MUTATIONS.REINVITE_USER);
export const useCreateBrand = () => useMutation(MUTATIONS.CREATE_BRAND);
export const useCreateTest = (options) =>
  useMutation(MUTATIONS.CREATE_TEST, {
    variables: {
      ...options,
    },
  });

export const useUploadTestMedia = () => {
  const { id: testId } = useParams();
  const { user } = useAuthContext();
  const { ErrorMessage, SuccessMessage } = useToasts();

  return useMutation(MUTATIONS.UPLOAD_TEST_MEDIA, {
    variables: {
      input: {
        testId,
        testUserId: user?.id,
      },
      skip: !user,
    },
    onCompleted: (res) => (res.uploadTestMedia?.id ? SuccessMessage('Image Added') : new Error()),
    onError: () => ErrorMessage('Error. Please, try again'),
  });
};

export const useEditTestMedia = () => {
  const { ErrorMessage } = useToasts();

  return useMutation(MUTATIONS.EDIT_TEST_MEDIA, {
    onCompleted: (res) => res.editTestMedia?.status || new Error(),
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};
export const useRemoveTestMedia = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();

  return useMutation(MUTATIONS.REMOVE_TEST_MEDIA, {
    onCompleted: (res) =>
      res.removeTestMedia?.status ? SuccessMessage('Image removed') : new Error(),
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};
export const useEditTest = () =>
  useMutation(MUTATIONS.EDIT_TEST, {
    refetchQueries: [GET_TESTS],
  });
export const useInviteUsersById = () => useMutation(MUTATIONS.INVITE_USERS_BY_ID);

export const useCreateReport = () => {
  const [createReport] = useMutation(MUTATIONS.CREATE_REPORT);
  const { addToast } = useToasts();
  const { refetch } = useGetTest();

  return ({ testId, input }) =>
    createReport({
      variables: {
        input: {
          ...input,
          testId,
        },
      },
      onCompleted(res) {
        if (!res.createReport?.status) return new Error();

        addToast({
          message: 'Report added',
          variant: 'success',
          timer: 6000,
        });
        refetch();

        return true;
      },
      onError() {
        addToast({
          message: 'Error. Please, try again.',
          variant: 'error',
          timer: 6000,
        });
      },
    });
};

export const useCloseTest = () => {
  const [mutation] = useMutation(MUTATIONS.CLOSE_TEST);
  const { id } = useParams();
  const { SuccessMessage } = useToasts();
  const { refetch } = useGetTest();
  const { refetch: refetchAllTests } = useGetTests();

  const closeTest = ({ testId = id, isPostReports = false } = {}) =>
    mutation({
      variables: {
        testId,
        isPostReports,
      },
      refetchQueries: [GET_TESTS],
      onCompleted: (data) => {
        if (!data?.closeTest?.status) return new Error();
        SuccessMessage('Test closed.');
        return id ? refetch() : refetchAllTests();
      },
    });

  return closeTest;
};

export const useChangeTesterStatus = () => {
  const [editTester] = useMutation(MUTATIONS.EDIT_TESTER);
  const { addToast } = useToasts();

  return ({ testerId, testerStatusInternal }) =>
    editTester({
      variables: {
        testerId,
        input: {
          testerInfo: {
            testerStatusInternal,
          },
        },
      },
      onCompleted() {
        addToast({
          message: 'Status updated.',
          variant: 'success',
          timer: 6000,
        });
      },
      onError() {
        addToast({
          message: 'Error. Please, try again.',
          variant: 'error',
          timer: 6000,
        });
      },
    });
};

export const useRemoveReport = () => {
  const [removeReport] = useMutation(MUTATIONS.REMOVE_REPORT);
  const { refetch } = useGetTest();
  const { addToast } = useToasts();

  return ({ reportId }) =>
    removeReport({
      variables: {
        reportId,
      },
      onCompleted(res) {
        if (!res.removeReport?.status) return new Error();

        addToast({
          message: 'Report removed',
          variant: 'success',
          timer: 6000,
        });
        refetch();

        return true;
      },
      onError() {
        addToast({
          message: 'Error. Please, try again.',
          variant: 'error',
          timer: 6000,
        });
      },
    });
};

export const useEditReport = () => {
  const [editReport] = useMutation(MUTATIONS.EDIT_REPORT);
  const { refetch } = useGetTest();
  const { addToast } = useToasts();

  return ({ reportId, input }) =>
    editReport({
      variables: {
        reportId,
        input,
      },
      onCompleted(res) {
        if (!res.editReport?.status) return new Error();
        return refetch();
      },
      onError() {
        addToast({
          message: 'Error. Please, try again.',
          variant: 'error',
          timer: 6000,
        });
      },
    });
};

export const useAddTestersFromCsv = () => {
  const [mutation] = useMutation(MUTATIONS.ADD_TESTERS_FROM_CSV);
  const { addToast } = useToasts();

  return ({ file }) =>
    mutation({
      variables: {
        file,
      },
      onError(error) {
        addToast({
          message: error.message,
          variant: 'error',
          timer: 6000,
        });
        throw new Error();
      },
    });
};

export const useSaveTestersFromCsv = () => {
  const [mutation] = useMutation(MUTATIONS.SAVE_TESTERS_FROM_CSV);
  const { refetch } = useGetTestersQuery();
  const { addToast } = useToasts();

  return ({ file }) =>
    mutation({
      variables: {
        file,
      },
      onCompleted: ({ saveTestersFromCsv }) => {
        if (!saveTestersFromCsv.status) return new Error();

        addToast({
          message: 'Testers removed',
          variant: 'success',
          timer: 6000,
        });
        refetch();

        return true;
      },
      onError() {
        addToast({
          message: 'Error. Please, try again.',
          variant: 'error',
          timer: 6000,
        });
      },
    });
};

export const useRemoveUser = () => {
  const [removeUser] = useMutation(MUTATIONS.REMOVE_USER);
  const { SuccessMessage, ErrorMessage } = useToasts();

  return ({ id }) =>
    removeUser({
      variables: {
        id,
      },
      onCompleted: (res) => (res.removeUser?.status ? SuccessMessage('User removed') : new Error()),
      onError: () => ErrorMessage('Error. Please, try again.'),
    });
};

export const useRemoveMyAccount = () => {
  const [mutation] = useMutation(MUTATIONS.REMOVE_MY_ACCOUNT);
  const { addToast } = useToasts();

  return () =>
    mutation({
      onCompleted({ removeMyAccount }) {
        if (!removeMyAccount?.status) return new Error();
        return true;
      },
      onError() {
        addToast({
          message: 'Error. Please, try again.',
          variant: 'error',
          timer: 6000,
        });
      },
    });
};

export const useEditUser = (opt) => {
  const [mutation, { loading }] = useMutation(MUTATIONS.EDIT_USER, opt);
  const { addToast } = useToasts();
  const { refetch } = useGetUserById();

  const editUser = ({ id, input }) =>
    mutation({
      variables: {
        id,
        input,
      },
      onError() {
        addToast({
          message: 'Error. Please, try again.',
          variant: 'error',
          timer: 3000,
        });
        refetch();
      },
    });

  return {
    editUser,
    loading,
  };
};

export const useDuplicateTest = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  const navigate = useNavigate();

  return useMutation(MUTATIONS.DUPLICATE_TEST, {
    onCompleted: (data) => {
      if (data?.duplicateTest?.id) {
        SuccessMessage('Test duplicated');

        navigate(`/tests/${data.duplicateTest.id}`);
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useRemoveCandidates = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.REMOVE_CANDIDATES, {
    onCompleted: (data) => {
      if (data?.removeCandidates?.status) {
        SuccessMessage('Status updated.');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useInviteCandidates = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.INVITE_CANDIDATES, {
    onCompleted: (data) => {
      if (data?.inviteCandidates?.status) {
        SuccessMessage('Invites Sent');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useReInviteCandidate = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.REINVITE_CANDIDATE, {
    onCompleted: (data) => {
      if (data?.reInviteCandidate?.status) {
        SuccessMessage('Re Invite successful');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useEnrollCandidate = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.ENROLL_CANDIDATE, {
    onCompleted: (data) => {
      if (data?.enrollCandidate?.status) {
        SuccessMessage('Candidate successfully enrolled');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useRejectCandidate = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.REJECT_CANDIDATE, {
    onCompleted: (data) => {
      if (data?.rejectCandidate?.status) {
        SuccessMessage('Candidate successfully rejected');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useAddCandidates = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.ADD_CANDIDATES, {
    onCompleted: (data) => {
      if (data?.addCandidates?.status) {
        SuccessMessage('Testers successfully added');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useInviteUser = () => {
  const [mutation, { loading }] = useMutation(MUTATIONS.INVITE_USERS, {
    refetchQueries: [GET_ADMINS, GET_CUSTOMERS, GET_TESTERS],
  });
  const { ErrorMessage } = useToasts();

  const inviteUsers = ({ emails, role, brandId }) =>
    mutation({
      variables: {
        emails,
        role,
        brandId,
      },
      onError(error) {
        ErrorMessage(error?.message);
      },
    });

  return {
    inviteUsers,
    loading,
  };
};

export const useEditTestTester = () => {
  const { SuccessMessage, ErrorMessage } = useToasts();
  return useMutation(MUTATIONS.EDIT_TEST_TESTER, {
    onCompleted: (data) => {
      if (data?.editTestTester?.status) {
        SuccessMessage('Grade changed');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useStartTestingTester = () => {
  const { SuccessMessage, ErrorMessage } = useToasts();
  return useMutation(MUTATIONS.START_TESTING_TESTER, {
    onCompleted: (data) => {
      if (data?.startTestingTester?.status) {
        SuccessMessage('Test starting, success');
      }
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useUpdateProfile = ({ successMessage } = {}) => {
  const { refetch } = useAuthContext();
  const { SuccessMessage } = useToasts();

  return useMutation(MUTATIONS.UPDATE_PROFILE, {
    onCompleted: (res) => {
      if (!res.updateProfile?.id) return new Error();
      refetch();
      return SuccessMessage(successMessage ?? 'Profile has been updated');
    },
  });
};

export const useCompleteTesterProfile = ({ isLastStep, setIsOpenCompleteModal }) => {
  const { refetch } = useAuthContext();
  const { SuccessMessage } = useToasts();

  return useMutation(MUTATIONS.COMPLETE_TESTER_PROFILE, {
    onCompleted: (res) => {
      if (!res.completeTesterProfile?.status) return new Error();
      refetch();
      if (isLastStep) {
        return setIsOpenCompleteModal(true);
      }
      return SuccessMessage('Profile has been updated');
    },
  });
};

export const useUpdateProfileWhithoutMessage = () => {
  const { refetch } = useAuthContext();

  return useMutation(MUTATIONS.UPDATE_PROFILE, {
    // eslint-disable-next-line consistent-return
    onCompleted: (res) => {
      if (!res.updateProfile?.id) return new Error();
      refetch();
    },
  });
};

export const useSetChoiceInvitedCandidate = () => {
  const navigate = useNavigate();
  const [setChoice, { loading, error }] = useMutation(MUTATIONS.SET_CHOICE_INVITED_CANDIDATE);

  const handleSetChoice = async (testId, status) =>
    setChoice({
      variables: {
        testId,
        status,
      },
      onCompleted: (res) => {
        if (!res.setChoiceInvitedCandidate?.status) return new Error();
        return navigate({
          pathname: '/invite-to-test/response',
          search: `?${createSearchParams({
            status,
          })}`,
        });
      },
    });
  return {
    handleSetChoice,
    loading,
    error,
  };
};

export const signUpByToken = () => {
  const [mutation, { loading }] = useMutation(MUTATIONS.SIGN_UP_BY_TOKEN);
  const [searchParams] = useSearchParams();
  const inviteToken = useMemo(() => searchParams.get('t'), [searchParams]);
  const { SuccessMessage, ErrorMessage } = useToasts();

  const signUp = (input) =>
    mutation({
      variables: {
        input,
        inviteToken,
      },
      onCompleted: (res) => {
        if (!res.signUpByToken.status) return new Error();

        return SuccessMessage('Password successfully created');
      },
      onError: () => {
        ErrorMessage('Error! Please, try again.');
      },
    });

  return {
    signUp,
    loading,
  };
};

export const useTesterUpdateProfile = () => {
  const { ErrorMessage } = useToasts();
  return useMutation(MUTATIONS.UPDATE_PROFILE, {
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useUnenrollTestTester = (onCompletedCallBack) => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.UNENROLL_TEST_TESTER, {
    onCompleted: (data) => {
      if (data?.unEnrollTestTester?.status) {
        SuccessMessage('User unenrolled');
        return onCompletedCallBack && onCompletedCallBack();
      }
      return true;
    },
    onError: () => ErrorMessage('Error. Please, try again.'),
  });
};

export const useSetIsAvailableStatus = () => {
  const { ErrorMessage, SuccessMessage } = useToasts();
  return useMutation(MUTATIONS.SET_IS_AVAILABLE_STATUS, {
    onCompleted: (res) => {
      if (!res.setIsAvailableStatus.status) return new Error();
      return SuccessMessage('Status has been updated');
    },
    onError: () => {
      ErrorMessage('Error. Please, try again.');
    },
  });
};

export const useUpdateUserWithRoleAdminById = () => {
  const { SuccessMessage } = useToasts();

  return useMutation(MUTATIONS.UPDATE_USER_WITH_ROLE_ADMIN_BY_ID, {
    refetchQueries: [GET_ADMIN_BY_ID],
    onCompleted: () => {
      return SuccessMessage('Profile has been updated');
    },
  });
};

export const useAuthCandidate = () => {
  const { handleSuccessAuth } = useAuthContext();

  return useMutation(MUTATIONS.AUTH_CANDIDATE, {
    onCompleted: async ({ authCandidate }) => {
      await cookies.set('token', authCandidate.token, {
        path: '/',
        maxAge: 86400,
      });
      handleSuccessAuth();
    },
  });
};
