import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';
import { ChangeAvatarModal } from 'src/components/UI';
import { useAuthContext } from 'src/hooks';

import { testerWrapperStyle } from '../components/userProfile/styles';
import TesterInfoFormikWrapper from '../components/userProfile/TesterInfo/TesterInfoFormikWrapper';

const TesterProfileComplete = () => {
  const { user, loading } = useAuthContext();
  const [isChangeAvatarModalOpen, setIsChangeAvatarModalOpen] = useState(false);

  const toggleChangeAvatarModal = useCallback(() => {
    setIsChangeAvatarModalOpen((prev) => !prev);
  }, []);

  return (
    <RequestHandler loading={loading}>
      <Box sx={testerWrapperStyle.wrapper}>
        <TesterInfoFormikWrapper toggleChangeAvatarModal={toggleChangeAvatarModal} />
        <ChangeAvatarModal
          open={isChangeAvatarModalOpen}
          onClose={toggleChangeAvatarModal}
          user={user}
        />
      </Box>
    </RequestHandler>
  );
};

export default TesterProfileComplete;
