import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { DetailInput, DetailLabel } from 'src/components/index';

import { detailsTabStyles } from './backDropStyles';

export const DetailsAdditionalClothingInfo = ({
  submitOnValueChange = true,
  toggleModal = null,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { values = {}, handleChange, handleSubmit } = useFormikContext();

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  return (
    <DetailLabel label='Additional Clothing Info'>
      <Box onClick={changeViewBox}>
        <Backdrop open={isEditMode} invisible={false} sx={detailsTabStyles.backDropStyle} />

        <DetailInput
          name='clothingSizeAdditionalInfo'
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={values.clothingSizeAdditionalInfo}
          placeholder='Additional info'
          submitOnValueChange={submitOnValueChange}
          toggleModal={toggleModal}
        />
      </Box>
    </DetailLabel>
  );
};
