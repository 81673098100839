import { Box, MenuItem, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { CheckIcon } from 'src/components/UI/icons/CheckIcon';
import { YesOrNotText } from 'src/constants/constants';
import { stringToBoolean } from 'src/utils/helpers';

import { stepsStyles } from '../styles';

const IndustryInsider = () => {
  const { handleChange, values } = useFormikContext();

  const handleBoolChange = (value) => () => {
    const name = 'isWorkForAnOutdoorCompany';

    handleChange({
      target: {
        name,
        value: stringToBoolean(value),
      },
    });
  };

  const isCheckedNo =
    stringToBoolean(values.isWorkForAnOutdoorCompany) === null
      ? null
      : !(
          stringToBoolean(values.isWorkForAnOutdoorCompany) !== null &&
          stringToBoolean(values.isWorkForAnOutdoorCompany)
        );

  return (
    <>
      <Box
        sx={{
          py: '6px',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            lineHeight: 1.16,
            fontWeight: '700',
            marginBottom: '8px',
          }}
        >
          Industry Insider
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            marginBottom: '8px',
          }}
        >
          Do you work in the outdoor industry?
        </Typography>
      </Box>
      {YesOrNotText.map(({ title, value }) => (
        <MenuItem
          key={title}
          value={value}
          sx={stepsStyles.selectItem}
          onClick={handleBoolChange(value)}
        >
          <Box className='check-icon' sx={stepsStyles.checkIcon}>
            <CheckIcon
              isChecked={
                title === 'Yes' ? stringToBoolean(values.isWorkForAnOutdoorCompany) : isCheckedNo
              }
            />
          </Box>
          {title}
        </MenuItem>
      ))}
    </>
  );
};

export default IndustryInsider;
